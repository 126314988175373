import React from 'react';
import { releaseData } from './releaseData';

export default function ReleaseInfo() {
  return (
    <div className="row">
      {releaseData.map((r, i) => (<div className="col my-2" key={i}>
        <div className="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item card-title" style={{ background: '#eeeeee' }}>{r.version} - {r.date}</li>
            <li class="list-group-item">{r.by} ({r.contact})</li>
            {r.add.length > 0 && <li class="list-group-item" style={{ background: '#c3f3c3' }}>
              add:<ul>{r.add.map((a, i) => <li key={i}>{a}</li>)}</ul>
            </li>}
            {r.change.length > 0 && <li class="list-group-item" style={{ background: '#f9f96c' }}>
              change:<ul>{r.change.map((c, i) => <li key={i}>{c}</li>)}</ul>
            </li>}
            {r.fix.length > 0 && <li class="list-group-item" style={{ background: '#eecaca' }}>
              fix:<ul>{r.fix.map((f, i) => <li key={i}>{f}</li>)}</ul>
            </li>}
          </ul>
        </div>
      </div>))}
    </div>
  )
}
