import React from 'react'

export default function CommonTable({ title, tableData, deleteCache }) {
  return (

    <table className="table table-bordered table-striped" style={{ overflowWrap: 'anywhere' }}>
      <thead>
        <tr>
          <td colSpan="2">
            {title} <button className="btn btn-outline-secondary btn-sm" style={{ float: 'right' }}
              data-toggle="modal" data-target="#notifyModal" onClick={() => deleteCache()}>delete</button>
          </td>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((r, i) => <tr key={i}>
          <td>{r.key}</td>
          <td>{r.value}</td>
        </tr>)}
      </tbody>
    </table>
  )
}
