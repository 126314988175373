import React, { useState, useEffect } from "react";
import axios from "../../../api/management_api";
import Loading from "../../common/loading/Loading";
import ErrorBody from "../../common/error/ErrorBody";
import LockImg from "../../../asset/image/lock.png";
import UnlockImg from "../../../asset/image/unlock.png";

function Protection(props) {
  const [protect, setProtect] = useState("LOCK");
  const [rtype, setRType] = useState("MAC");
  const [version, setVersion] = useState(0);
  const [msg, setMsg] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState();
  const [techVersion, setTechVersion] = useState({
    MAC: [],
    SPEED: [],
    CFS: [],
  });

  useEffect(() => {
    fetchVersion();
    setProtect("LOCK");
    setRType("MAC");
    setVersion(0);
    setError();
    setMsg();
    setLoad(false);
  }, [props]);

  const fetchVersion = () => {
    axios
      .get("/v1/catalog/getAllHistoryEntries")
      .then((res) => {
        setTechVersion({
          MAC: res.data
            .filter((item) => item.type === "MAC")
            .map((data) => {
              return { ver: data.version, vDate: data.createdDate };
            }),
          SPEED: res.data
            .filter((item) => item.type === "SPEED")
            .map((data) => {
              return { ver: data.version, vDate: data.createdDate };
            }),
          CFS: res.data
            .filter((item) => item.type === "CFS")
            .map((data) => {
              return { ver: data.version, vDate: data.createdDate };
            }),
          COMMERCIAL_SPEED: res.data
            .filter((item) => item.type === "COMMERCIAL_SPEED")
            .map(data => {
              return { ver: data.version, vDate: data.createdDate };
            })
        });
      })
      .catch((err) => setError(err.message));
  }

  const protectionSubmit = () => {
    setError();
    setMsg();
    setLoad(true);
    axios
      .post(
        `/v1/catalog/vendorResourceProtection?vendorResourceProtection=${protect}&resourceType=${rtype}&version=${version}`
      )
      .then((res) => {
        setLoad(false);
        if (res.data.statusMessage.includes("wrong")) {
          setError(res.data.statusMessage);
        } else {
          setMsg({ match: protect, data: res.data.statusMessage });
        }
      })
      .catch((err) => {
        setLoad(false);
        setError(err.message);
      });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-row p-3">
          <div className="form-group col-md-3">
            <label htmlFor="protection">
              <strong>Protection</strong>
            </label>
            <select
              id="protection"
              className="form-control"
              value={protect}
              onChange={(e) => setProtect(e.target.value)}
            >
              <option defaultValue>LOCK</option>
              <option>UNLOCK</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="type">
              <strong>Type</strong>
            </label>
            <select
              id="type"
              className="form-control"
              value={rtype}
              onChange={(e) => setRType(e.target.value)}
            >
              <option defaultValue>MAC</option>
              <option>SPEED</option>
              <option>CFS</option>
              <option>COMMERCIAL_SPEED</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="version">
              <strong>Version</strong>
            </label>
            <select
              id="version"
              className="form-control"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            >
              <option defaultValue>select version</option>
              {rtype === "MAC" && techVersion.MAC.length > 0
                ? techVersion.MAC.map((opt, index) => (
                  <option key={index} value={opt.ver}>
                    {opt.ver} - ({new Date(opt.vDate).toLocaleString()})
                  </option>
                ))
                : null}
              {rtype === "SPEED" && techVersion.SPEED.length > 0
                ? techVersion.SPEED.map((opt, index) => (
                  <option key={index} value={opt.ver}>
                    {opt.ver} - ({new Date(opt.vDate).toLocaleString()})
                  </option>
                ))
                : null}
              {rtype === "CFS" && techVersion.CFS.length > 0
                ? techVersion.CFS.map((opt, index) => (
                  <option key={index} value={opt.ver}>
                    {opt.ver} - ({new Date(opt.vDate).toLocaleString()})
                  </option>
                ))
                : null}
              {rtype === "COMMERCIAL_SPEED" && techVersion.COMMERCIAL_SPEED.length > 0
                ? techVersion.COMMERCIAL_SPEED.map((opt, index) => (
                  <option key={index} value={opt.ver}>
                    {opt.ver} - ({new Date(opt.vDate).toLocaleString()})
                  </option>
                ))
                : null}
            </select>
          </div>
          <div
            className="form-group col-md-3"
            style={{ alignSelf: "flex-end" }}
          >
            <input
              type="submit"
              className="btn btn-block btn-info"
              value="Submit"
              onClick={protectionSubmit}
              disabled={version && version !== "select version" ? false : true}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        {load && <Loading />}
        {error && <ErrorBody errorMessage={error} />}
        {msg ? (
          <div className="loading-class">
            <div>
              {msg.match === "LOCK" && (
                <img
                  style={{ width: "23%" }}
                  src={LockImg}
                  alt="lock"
                />
              )}
              {msg.match === "UNLOCK" && (
                <img
                  style={{ width: "23%" }}
                  className="mb-3"
                  src={UnlockImg}
                  alt="unlock"
                />
              )}
            </div>
            <div>
              <h1>{msg.data}</h1>
            </div>
          </div>
        ) : (
            !load &&
            !error && (
              <div className="loading-class">
                <div>
                  <img
                    style={{ width: "23%" }}
                    src={LockImg}
                    alt="lock"
                  />
                  <img
                    style={{ width: "23%" }}
                    className="mb-3"
                    src={UnlockImg}
                    alt="unlock"
                  />
                </div>
                <div>
                  <h2>Please perform LOCK/UNLOCK resource</h2>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
}

export default React.memo(Protection);
