import React, { useState } from "react";
import { useTable, useSortBy, useFilters } from 'react-table';
import { ReactTableFilter } from './ReactTableFilter';
import Modal from '../../common/modal/Modal';
import HistoryModal from './HistoryModal';
import axios from '../../../api/management_api';
import ErrorBody from '../../common/error/ErrorBody';

function ReactDataTable({ rowData, archive }) {

  const [rowId, setRowId] = useState();
  const [modal, setModal] = useState({ title: 'title', data: '' });
  const [info, setInfo] = useState();
  const [error, setError] = useState();

  const setDetails = (id) => {
    setInfo();
    setError();
    setModal({ title: `Details (Request Id: ${id}):`, data: '' });
    axios.get(`/v1/requestHistory/getRequestHistoryById?requestId=${id}&archive=${archive}`).then(response => {
      setInfo({ res: response.data.responseSchema, req: response.data.requestSchema })
    }).catch(err => setError(err.message))
  }

  const data = React.useMemo(
    () => rowData ? rowData.map(data => {
      return {
        id: data.id,
        trackingId: data.trackingId,
        serviceType: data.serviceType,
        user: data.user,
        orderNumber: data.orderNumber,
        primaryIdentifier: data.primaryIdentifier,
        requestDate: data.requestDate,
        responseTime: data.responseTime,
        details: { id: data.id, track: data.trackingId }
      }
    }) : [],
    [rowData]
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Request Id', Footer: 'Request Id', accessor: 'id' },
      { Header: 'Tracking Id', Footer: 'Tracking Id', accessor: 'trackingId' },
      {
        Header: 'Details', Footer: 'Details', accessor: 'details',
        Cell: ({ value }) => <button className="btn btn-sm btn-secondary"
          data-toggle="modal" data-target='#tran-modal'
          onClick={() => setDetails(value.id)}>
          view
        </button>
      },
      { Header: 'Type', Footer: 'Type', accessor: 'serviceType' },
      { Header: 'User', Footer: 'User', accessor: 'user' },
      { Header: 'Order Number', Footer: 'Order Number', accessor: 'orderNumber' },
      { Header: 'Identifier', Footer: 'Identifier', accessor: 'primaryIdentifier' },
      {
        Header: 'Request Date', Footer: 'Request Date', accessor: 'requestDate',
        Cell: ({ value }) => new Date(value).toJSON()
      },
      { Header: 'Response Time', Footer: 'Response Time', accessor: 'responseTime' }
    ],
    // eslint-disable-next-line
    []
  )

  const defaultColumn = React.useMemo(() => {
    return {
      Filter: ReactTableFilter
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useFilters,
    useSortBy,
  )


  return (
    <>
      <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
        <button id="direct-Search" data-toggle="modal" data-target='#tran-modal' hidden></button>
        <Modal id="tran-modal" title={modal.title}
          res={info ? <HistoryModal history={info} /> : ''}
          error={error ? <ErrorBody errorMessage={error} /> : ''} />
        <table {...getTableProps()}
          className="table table-sm table-striped table-bordered">
          <thead className="thead-light">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.Header}>
                    <span  {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr className={rowId === row.id ? 'select-row' : ''} {...row.getRowProps()} onClick={() => setRowId(row.id)}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          <tfoot className="thead-light">
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <th {...column.getFooterProps()}>{column.render("Footer")}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  )
}

export default React.memo(ReactDataTable);