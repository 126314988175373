import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faPlusSquare, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ErrorBody from '../../common/error/ErrorBody';
import Loading from '../../common/loading/Loading';
import Notify from '../Notify';
import axios,{hasAccess} from '../../../api/management_api';


function QosTable(){
    const [data,setData]=useState();
    const [show,setShow]=useState([]);
    const [edit,setEdit]=useState({id:''});
    const [notifyData,setNotifyData]=useState({title:''})
    const [error,setError]=useState();
    const access=useState(hasAccess())[0];

    useEffect(()=>{
        refreshData();
    },[])

    const refreshData=()=>{
        axios.get('/v1/qos/getAllQosInterface')
        .then(res=>{
            setData(res.data)
        })
        .catch(err=>{
            setError(err.message);
        })
    }


    const formChange=(e)=>{
        setEdit({...edit,[e.target.name]:e.target.value});
    }
    const childformChange=(id,e)=>{
        setEdit({
            ...edit,
            qosEntries:edit.qosEntries.map(item=>{
                if(item.id===id){
                    return {
                        ...item,
                        [e.target.name]:e.target.value
                    }
                }else{
                    return item
                }
            })
        })
    }

    const clickSave=()=>{
        setNotifyData({title:`Want to save ${edit.vendor} ?`,method:performSave})
    }
    const performSave=()=>{
        setNotifyData({title:`trying to save ${edit.vendor} !`,method:''})
        axios.post('/v1/qos/createQosInterface',edit).then(res=>{
            refreshData();
            setNotifyData({title:`Successfully saved ${edit.vendor} !`,method:''});
            setEdit({id:''});
        }).catch(err=>{
            setNotifyData({title:err.message,method:''});
            setEdit({id:''});
        })
    }

    const addNew=()=>{
        let newData={
            id:0,
            interfaceSpeedDown: 0,
            interfaceSpeedUp: 0,
            maxTechnicalSpeedDown: 0,
            maxTechnicalSpeedUp: 0,
            qosEntries:[],
            vendor: ""
        }
        setEdit(newData);
        
    }

    const addEntries=()=>{
        let newData={
            id:Date.now(),
            down: 0,
            methodType: "",
            qos: "",
            up: 0
        }
        setEdit({...edit,qosEntries:[...edit.qosEntries,newData]});
    }

    const childDelete=(ch,qos)=>{
        setNotifyData({title:`Want to delete ${qos} from ${edit.vendor} ?`,method:()=>performChildDelete(ch,qos)})
    }

    const performChildDelete=(ch,qos)=>{
        setEdit({
            ...edit,
            qosEntries:edit.qosEntries.filter(item=>item.id!==ch)
        })
        setNotifyData({title:`Deleted ${qos}. Please save ${edit.vendor}!`,method:''})
    }

    const deleteClick=()=>{
        setNotifyData({title:`Want to delete ${edit.vendor} ?`,method:performDeleteClick})
    }

    const performDeleteClick=()=>{
        setNotifyData({title:`Trying to delete ${edit.vendor} !`,method:''});
        axios.delete(`/v1/qos/deleteQosVendorInterface?qosVendorInterfaceId=${edit.id}`).then(res=>{
            refreshData();
            setNotifyData({title:`Successfully deleted ${edit.vendor} !`,method:''});
            setEdit({id:''});
        }).catch(err=>{
            setNotifyData({title:err.message,method:''});
            setEdit({id:''});
        })
    }
    const clickShow=(id)=>{
        if(show.includes(id)){
            setShow([...show.filter(item=>item!==id)])
        }else{
            setShow([...show,id])
        }
    }

    return (
        <div className="table-responsive" >
            <Notify notifyData={notifyData} />
            {error?<ErrorBody errorMessage={error} />:
        <table className="table table-bordered table-striped table-sm">
        <thead >
            <tr>
                {access?<th><button className="btn btn-sm btn-success" onClick={()=>addNew()} disabled={edit.id===0?true:false}>
                    <FontAwesomeIcon icon={faPlusSquare}/></button></th>:null}
                <th>vendor</th>
                <th>Interface Up</th>
                <th>Interface Down</th>
                <th>Technical Up</th>
                <th>Technical Down</th>
                <th>Entries</th>
               
            </tr>
        </thead>
        <tbody>
            { //create new
            edit.id === 0 ?<>
            <tr>
                <td><div style={{display:'inline-flex'}}>
                    <button className="btn btn-sm btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                        disabled={edit.vendor?false:true} onClick={()=>clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                    <button className="btn btn-sm btn-danger" onClick={()=>setEdit({id:''})}><FontAwesomeIcon icon={faTimes} /></button>
                </div></td>
                <td><input type="text" className="form-control form-control-sm"
                style={{width:"auto"}} name='vendor' value={edit.vendor} onChange={(e)=>formChange(e)}
                 /></td>
                <td><input type="number" className="form-control form-control-sm" name="interfaceSpeedUp"
                value={edit.interfaceSpeedUp} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="interfaceSpeedDown"
                 value={edit.interfaceSpeedDown} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="maxTechnicalSpeedUp"
                 value={edit.maxTechnicalSpeedUp} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="maxTechnicalSpeedDown"
                 value={edit.maxTechnicalSpeedDown} onChange={(e)=>formChange(e)} /></td>
                 <td>
                    <button className="btn btn-sm btn-outline-secondary" onClick={()=>clickShow(edit.id)}>{show.includes(edit.id)?'close':'view'}</button>
                </td>
            </tr>
            <tr><td colSpan="7">
            {show.includes(edit.id)
              && <table className="table table-bordered table-sm" >
              <thead style={{background:'#b0ded2'}}>
              <tr>
                  <th>qos</th>
                  <th>up</th>
                  <th>down</th>
                  <th>methodType</th>
              </tr>
              </thead>
              <tbody>
                  {edit.qosEntries.map(ent=><tr key={ent.id}>
                      <td><input className="form-control form-control-sm" value={ent.qos} 
                        name="qos"  onChange={(e)=>childformChange(ent.id,e)}/></td>
                      <td><input type="number" className="form-control form-control-sm" value={ent.up} 
                          name="up" onChange={(e)=>childformChange(ent.id,e)}/></td>
                      <td><input type="number" className="form-control form-control-sm" value={ent.down}
                          name="down" onChange={(e)=>childformChange(ent.id,e)} /></td>
                      <td><input className="form-control form-control-sm" value={ent.methodType}
                          name="methodType" onChange={(e)=>childformChange(ent.id,e)} /></td>
                          <td style={{textAlign:'center'}}><button className="btn btn-sm btn-outline-danger"
                          data-toggle="modal" data-target="#notifyModal" 
                          onClick={()=>childDelete(ent.id,ent.qos)}><FontAwesomeIcon icon={faTrashAlt}/></button></td>
                  </tr>)}
                  <tr>
                      <td colSpan="4" style={{textAlign:'center'}}><button className="btn btn-sm btn-success"
                      onClick={()=>addEntries()}><FontAwesomeIcon icon={faPlusSquare}/></button></td>
                  </tr>
                    </tbody></table>}
                    </td></tr></>
            :null}
                


            { //existing
            data?data.map((item,index)=><React.Fragment key={index}><tr>
                {edit.id===item.id?<><td><div style={{display:'inline-flex'}}>
                    <button className="btn btn-sm btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                        disabled={edit.vendor?false:true} onClick={()=>clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                    <button className="btn btn-sm btn-danger" onClick={()=>setEdit({id:''})}><FontAwesomeIcon icon={faTimes} /></button>
                </div></td>
                <td><input type="text" className="form-control form-control-sm"
                style={{width:"auto"}} name='vendor' value={edit.vendor} onChange={(e)=>formChange(e)}
                 /></td>
                <td><input type="number" className="form-control form-control-sm" name="interfaceSpeedUp"
                value={edit.interfaceSpeedUp} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="interfaceSpeedDown"
                 value={edit.interfaceSpeedDown} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="maxTechnicalSpeedUp"
                 value={edit.maxTechnicalSpeedUp} onChange={(e)=>formChange(e)} /></td>
                <td><input type="number" className="form-control form-control-sm" name="maxTechnicalSpeedDown"
                 value={edit.maxTechnicalSpeedDown} onChange={(e)=>formChange(e)} /></td>
               </>
                :
                <>
                   {access?<td> <button className="btn btn-sm btn-info"
                   onClick={()=>setEdit(item)}><FontAwesomeIcon icon={faEdit} /></button></td>:null}
                    <td>{item.vendor}</td>
                    <td>{item.interfaceSpeedUp}</td>
                    <td>{item.interfaceSpeedDown}</td>
                    <td>{item.maxTechnicalSpeedUp}</td>
                    <td>{item.maxTechnicalSpeedDown}</td>
                </>
                }
                            
                <td>
                    <button className="btn btn-sm btn-outline-secondary" onClick={()=>clickShow(item.id)}>{show.includes(item.id)?'close':'view'}</button>
                </td>
                {edit.id!==0&&edit.id===item.id&&<td style={{textAlign:'center'}}><button className="btn btn-sm btn-outline-danger" 
                   data-toggle="modal" data-target="#notifyModal"
                   onClick={()=>deleteClick()}><FontAwesomeIcon icon={faTrashAlt}/></button></td>}
            </tr>
            <tr><td colSpan="7">
                  {show.includes(item.id)
                    && <table className="table table-bordered table-sm" >
                    <thead style={{background:'#b0ded2'}}>
                    <tr>
                        <th>qos</th>
                        <th>up</th>
                        <th>down</th>
                        <th>methodType</th>
                    </tr>
                    </thead>
                    <tbody>
                    {edit.id===item.id?<>
                        {edit.qosEntries.map(ent=><tr key={ent.id}>
                            <td><input className="form-control form-control-sm" value={ent.qos} 
                              name="qos"  onChange={(e)=>childformChange(ent.id,e)}/></td>
                            <td><input type="number" className="form-control form-control-sm" value={ent.up} 
                                name="up" onChange={(e)=>childformChange(ent.id,e)}/></td>
                            <td><input type="number" className="form-control form-control-sm" value={ent.down}
                                name="down" onChange={(e)=>childformChange(ent.id,e)} /></td>
                            <td><input className="form-control form-control-sm" value={ent.methodType}
                                name="methodType" onChange={(e)=>childformChange(ent.id,e)} /></td>
                                <td style={{textAlign:'center'}}><button className="btn btn-sm btn-outline-danger"
                                data-toggle="modal" data-target="#notifyModal" 
                                onClick={()=>childDelete(ent.id,ent.qos)}><FontAwesomeIcon icon={faTrashAlt}/></button></td>
                        </tr>)}
                        <tr>
                            <td colSpan="4" style={{textAlign:'center'}}><button className="btn btn-sm btn-success"
                            onClick={()=>addEntries()}><FontAwesomeIcon icon={faPlusSquare}/></button></td>
                        </tr></>
                    :
                    item.qosEntries.map(ent=><tr key={ent.id}>
                        <td>{ent.qos}</td>
                            <td>{ent.up}</td>
                            <td>{ent.down}</td>
                            <td>{ent.methodType}</td>
                    </tr>)}
                    
                    </tbody>
                    </table>}
                </td></tr>
            </React.Fragment>
            ):<tr style={{background:'#fff'}}><td colSpan="7"><Loading /></td></tr>}
            
        </tbody>
        </table>}
        </div>
    );
}

export default React.memo(QosTable);