import React from 'react';
import QosTable from './QosTable';
import QosProfile from './QosProfile';

function Qos() {
    return (
        <div className="card">
            <h5 className="card-header">Quality of service</h5>
            <div className="card-body" style={{ display: 'grid' }}>
                <div className="div-table">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active h6" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
                            <a className="nav-item nav-link h6" id="nav-table-tab" data-toggle="tab" href="#nav-table" role="tab" aria-controls="nav-contact" aria-selected="false">Technical</a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        
                        <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <QosProfile />
                        </div>
                        <div className="tab-pane fade" id="nav-table" role="tabpanel" aria-labelledby="nav-table-tab">
                            <QosTable />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Qos;