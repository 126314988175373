export const version = "v1.1.3";

export const requestType = [
    'Scheduler',
    'ServiceQualificationByAddress',
    'ServiceQualificationByAddressV2',
    'VisitationBySubscriber',
    'VisitationByAddress',
    'CallBandwidthByAddress',
    'TechParam',
    'SpeedCalculation',
    'SpeedCalculationV2',
    'OSSCHAC_getVisitationBySubscriber',
    'OSSCHAC_GetTechParam',
    'OSSCHAC_NAL',
    'Property',
    'QOS',
    'CPE',
    'Lock/Unlock_Version',
    'Update_VendorResource',
    'TdcWhiteListJob',
    'OpnenetWhiteListJob',
    'UpdateToAccessInventory',
    'ServiceQualificationBynamId',
    'ServiceQualificationByInstallationId',
    'CalcQosNeeded',
    'PreSalesAppointment'
]

export const vendors = [
    { name: "TDC", value: 1 },
    { name: "ENIIG", value: 2 },
    { name: "FIBIA", value: 3 },
    { name: "EWII", value: 4 },
    { name: "SYDENERGI", value: 5 },
    { name: "NORDENERGI", value: 6 },
    { name: "THYMORS", value: 7 },
    { name: "ENERGIFYN", value: 8 },
    { name: "RAH", value: 11 },
    { name: "SEF", value: 12 },
    { name: "GCP", value: 13 }
];