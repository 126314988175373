import { PublicClientApplication } from "@azure/msal-browser";
import App from './App';
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "../api/msalconfig";


const msalInstance = new PublicClientApplication(msalConfig);


function Oauth() {
    return (
        <MsalProvider instance={msalInstance} forceLogin={true}>
            <App/>
        </MsalProvider>
    );
}

export default Oauth;