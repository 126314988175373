import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBody from '../../common/error/ErrorBody';
import { faPlusSquare, faEdit, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Notify from '../Notify';
import Loading from '../../common/loading/Loading';
import axios, { hasAccess } from '../../../api/management_api';

function Property() {
    const [tableData, setTableData] = useState();
    const [edit, setEdit] = useState({ id: '' });
    const [error, setError] = useState();
    const [notifyData, setNotifyData] = useState({ title: '' });
    const access = useState(hasAccess())[0];

    //call on load
    useEffect(() => {
        refreshData();
    }, [])

    const refreshData = () => {
        axios.get('/v1/property/getAllProperies')
            .then(res => {
                setTableData(res.data);
            }).catch(err => setError(err.message));
    }

    const clickSave = () => {
        setNotifyData({ title: `Want to save ${edit.propertyName} ?`, method: performSave })
    }
    const performSave = () => {
        setNotifyData({ title: `trying to save ${edit.propertyName} !`, method: '' })
        axios.post('/v1/property/createProperty', edit)
            .then(res => {
                refreshData();
                setNotifyData({ title: `Successfully saved ${edit.propertyName} !`, method: '' });
                setEdit({ id: '' });
            }).catch(err => {
                setNotifyData({ title: err.message, method: '' });
                setEdit({ id: '' });
            })
    }

    const deleteClick = () => {
        setNotifyData({ title: `Want to delete ${edit.propertyName} ?`, method: performDeleteClick })
    }

    const performDeleteClick = () => {
        setNotifyData({ title: `Trying to delete ${edit.propertyName} !`, method: '' });
        axios.delete(`/v1/property/deleteProperty?propertyId=${edit.id}`)
            .then(res => {
                refreshData();
                setNotifyData({ title: `Successfully deleted ${edit.propertyName} !`, method: '' });
                setEdit({ id: '' });
            }).catch(err => {
                setNotifyData({ title: err.message, method: '' });
                setEdit({ id: '' });
            })
    }

    return (
        <div className="card">
            <Notify notifyData={notifyData} />
            <h5 className="card-header">Property</h5>
            <div className="card-body table-responsive" style={{ maxHeight: '75vh' }}>
                {error ? <ErrorBody errorMessage={error} /> :
                    <table className="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                {access ? <th style={{ width: '8%' }}>
                                    <button className="btn btn-success" disabled={edit.id === 0 ? true : false}
                                        onClick={() => setEdit({
                                            id: 0,
                                            propertyName: '',
                                            propertyValue: ''
                                        })}>
                                        <FontAwesomeIcon icon={faPlusSquare} /></button></th> : null}
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody style={{ overflowWrap: 'anywhere' }}>
                            {
                                //add new property
                                edit.id === 0 ? <tr>
                                    <td><div style={{ display: 'inline-flex' }}>
                                        <button className="btn btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                            disabled={edit.propertyName ? false : true}
                                            onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                        <button className="btn btn-danger" onClick={() => setEdit({ id: '' })}>
                                            <FontAwesomeIcon icon={faTimes} /></button>
                                    </div></td>
                                    <td><input type="text" className="form-control form-control-sm" value={edit.propertyName}
                                        onChange={(e) => setEdit({ ...edit, propertyName: e.target.value })} /></td>
                                    <td><input type="text" className="form-control form-control-sm" value={edit.propertyValue}
                                        onChange={(e) => setEdit({ ...edit, propertyValue: e.target.value })} /></td>
                                </tr> : null
                            }

                            {// existing table if data present
                                tableData ? tableData.map((item, index) => <tr key={index}>
                                    {item.id === edit.id ? <>
                                        <td><div style={{ display: 'inline-flex' }}>
                                            <button className="btn btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                                disabled={edit.propertyName ? false : true}
                                                onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                            <button className="btn btn-danger" onClick={() => setEdit({ id: '' })}>
                                                <FontAwesomeIcon icon={faTimes} /></button>
                                        </div></td>
                                        <td><input type="text" className="form-control " value={edit.propertyName}
                                            onChange={(e) => setEdit({ ...edit, propertyName: e.target.value })} /></td>
                                        <td><input type="text" className="form-control " value={edit.propertyValue}
                                            onChange={(e) => setEdit({ ...edit, propertyValue: e.target.value })} /></td>
                                        <td style={{ textAlign: 'center' }}><button className="btn btn-outline-danger"
                                            data-toggle="modal" data-target="#notifyModal"
                                            onClick={() => deleteClick()}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                    </> :
                                        <>
                                            {access ? <td> <button className="btn btn-info"
                                                onClick={() => setEdit(item)}>
                                                <FontAwesomeIcon icon={faEdit} /></button></td> : null}
                                            <td>{item.propertyName}</td>
                                            <td>{item.propertyValue}</td>
                                        </>
                                    }

                                </tr>) : <tr style={{ background: '#fff' }}><td colSpan="3"><Loading /></td></tr>}
                        </tbody>
                    </table>}
            </div>
        </div>
    );
}

export default React.memo(Property);