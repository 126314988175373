import React, { useState } from 'react';
import axios from '../../../api/management_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import { vendors } from '../../../data/constant';
import ErrorBody from '../../common/error/ErrorBody';
import AiTable from './AiTable';

export default function AccessInventory() {
    const [formData, setFormData] = useState({
        addressId: "",
        installationId: "",
        vendorAddressId: "",
        externalInstallationId: "",
        installationCode: "",
        infrastructureOwner: "",
        vendorId: "",
        pageId: 0,
        pageSize: "1000"
    });

    const [error, setError] = useState();
    const [info, setInfo] = useState([]);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMad, setShowMad] = useState(true);
    const [madDetails, setMadDetails] = useState({
        houseNumber: "",
        houseLetter: "",
        side: "",
        floor: "",
        streetName: "",
        postCode: ""
    });

    const searchHistory = (e, pageNo) => {
        e.preventDefault();
        setError();
        setShowMad(false);
        setLoad(true);
        setInfo([]);
        setFormData(pre => ({ ...pre, pageId: pageNo }));
        axios.post(`/v2/accessinventory/getAccessInventory`, {
            ...formData,
            pageId: pageNo
        }).then(res => {
            setLoad(false);
            if (res.data.length > 0) {
                setInfo(res.data);
            } else {
                setError("No Data Found");
            }

        }).catch(err => {
            setLoad(false);
            setError(err.message);
        });

    }

    const resetHistory = () => {
        setFormData({
            addressId: "",
            installationId: "",
            vendorAddressId: "",
            externalInstallationId: "",
            installationCode: "",
            infrastructureOwner: "",
            vendorId: "",
            pageId: 0,
            pageSize: "1000"
        });
        resetMad();
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData(pre => ({ ...pre, [name]: value }));
    }

    const resetMad = () => {
        setMadDetails({
            houseNumber: "",
            houseLetter: "",
            side: "",
            floor: "",
            streetName: "",
            postCode: ""
        });
    }

    const handleMad = (e) => {
        let name = e.target.name;
        let value = e.target.value.trim();
        setMadDetails(pre => ({ ...pre, [name]: value }));
    }

    const findMad = () => {
        setLoading(true);
        axios.post("/v1/masterAddressCache/getMadIDbyAddress", madDetails)
            .then(res => {
                setFormData({ ...formData, addressId: res.data.addressSpecificId });
                setLoading(false);
            }).catch(() => {
                setFormData({ ...formData, addressId: "Not Found" });
                setLoading(false);
            });
    }
    return (
        <div>
            <div className="card">
                <h5 className="card-header">Access Inventory Search</h5>
                <div className="div-table my-2">
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="address">Adress Id</label>
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" name="addressId"
                                    value={formData.addressId} onChange={handleChange} />
                                <div className="input-group-append" title={showMad ? "" : "find mad id by address"}>
                                    <button className="btn btn-sm btn-secondary" type="button"
                                        onClick={() => setShowMad(pre => !pre)}>
                                        {showMad ? "Hide" : "Find"}</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="installationId">Installation Id</label>
                            <input type="text" className="form-control form-control-sm" name="installationId"
                                value={formData.installationId}
                                onChange={handleChange} />
                        </div>

                        <div className="form-group col">
                            <label htmlFor="response">Vendor</label>
                            <select className="form-control form-control-sm" name="vendorId"
                                value={formData.vendorId} onChange={handleChange}>
                                <option value="">All</option>
                                {vendors.map((v, index) => <option key={index} value={v.value}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="vendorAddressId">Vendor Address Id</label>
                            <input type="text" className="form-control form-control-sm" name="vendorAddressId"
                                value={formData.vendorAddressId}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col">
                            <label htmlFor="externalInstallationId">External Installation Id</label>
                            <input type="text" className="form-control form-control-sm" name="externalInstallationId"
                                value={formData.externalInstallationId}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col">
                            <label htmlFor="installationCode">Installation Code</label>
                            <input type="text" className="form-control form-control-sm" name="installationCode"
                                value={formData.installationCode}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col">
                            <label htmlFor="infrastructureOwner">Infrastructure Owner</label>
                            <input type="text" className="form-control form-control-sm" name="infrastructureOwner"
                                value={formData.infrastructureOwner}
                                onChange={handleChange} />
                        </div>
                        {showMad && <div className="div-table col-md-12 mx-auto" style={{ background: "#f0f1f375" }}>
                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label htmlFor="madId">Street Name</label>
                                    <input type="text" className="form-control form-control-sm" name="streetName"
                                        value={madDetails.streetName} onChange={handleMad} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="installationId">House no</label>
                                    <input type="text" className="form-control form-control-sm" name="houseNumber"
                                        value={madDetails.houseNumber} onChange={handleMad} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="installationId">House letter</label>
                                    <input type="text" className="form-control form-control-sm" name="houseLetter"
                                        value={madDetails.houseLetter} onChange={handleMad} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="installationId">Floor</label>
                                    <input type="text" className="form-control form-control-sm" name="floor"
                                        value={madDetails.floor} onChange={handleMad} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="installationId">Side</label>
                                    <input type="text" className="form-control form-control-sm" name="side"
                                        value={madDetails.side} onChange={handleMad} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="installationId">Post Code</label>
                                    <input type="text" className="form-control form-control-sm" name="postCode"
                                        value={madDetails.postCode} onChange={handleMad} />
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="col-sm" style={{ textAlign: "center" }}>
                                    <button type="submit" className="btn btn-secondary ml-2"
                                        onClick={() => findMad()} disabled={loading}>{loading ? "Wait" : "Get MadId"}</button>
                                </div>
                            </div>
                        </div>}
                        <div className="col-md-12">
                            <div className="row">
                                <div className="form-group col-md-2">
                                    <label htmlFor="response">Records</label>
                                    <select className="form-control form-control-sm" name="pageSize"
                                        value={formData.pageSize}
                                        onChange={handleChange}>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-8 my-auto text-center">
                                    <button type="submit" id="search-btn" className="btn btn-info"
                                        onClick={(e) => searchHistory(e, 0)}>Search</button>
                                    <button id="search-btn" className="btn btn-danger ml-2"
                                        onClick={() => resetHistory()}>Reset</button>
                                    {process.env.REACT_APP_ENVIRONMENT == "prod"
                                    ? ""
                                    :<CSVLink className="btn btn-info ml-2" filename="access-inventory-search.csv" data={info}>
                                         Export to CSV
                                     </CSVLink>
                                    }
                                </div>
                                <div className="form-group col-md-2 my-auto text-right">
                                    <button id="search-btn" className="btn btn-info "
                                        disabled={formData.pageId <= 0}
                                        onClick={(e) => searchHistory(e, formData.pageId - 1)}
                                    >&lt;</button>
                                    <i>Page: {formData.pageId} </i>
                                    <button id="search-btn" className="btn btn-info ml-2"
                                        disabled={info.length < formData.pageSize}
                                        onClick={(e) => searchHistory(e, formData.pageId + 1)}
                                    >&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-table mb-3">
                    {error ? <ErrorBody errorMessage={error} /> :
                        info.length > 0 ? <AiTable rowData={info} /> :
                            <div className="text-center p-4 text-wrap">
                                <FontAwesomeIcon className="mb-3" icon={faSearch} size="5x" color="rgb(87, 179, 193)"
                                    spin={load ? true : false} />
                                <h2 style={{ color: "rgb(73, 80, 87)" }}>
                                    {load ? "Loading" : "Access Inventory"}</h2>
                            </div>}
                </div>
            </div>

        </div>
    )
}
