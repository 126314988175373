import React from 'react';
import {useState} from 'react';
import PortalInfo from './PortalInfo';
import ReleaseInfo from './ReleaseInfo';

function Home() {
    const [select, setSelect] = useState("Portal Info");

    return (
        <div className="card">
            <h5 className="card-header">
                {select}
                <div className="btn-group float-right" role="group" aria-label="Basic example">
                    <button type="button" className={select === "Portal Info" ? "btn btn-info" : "btn btn-outline-info"}
                            onClick={() => setSelect("Portal Info")}>Portal Info
                    </button>
                    <button type="button"
                            className={select === "Release Info" ? "btn btn-info" : "btn btn-outline-info"}
                            onClick={() => setSelect("Release Info")}>Release Info
                    </button>
                </div>
            </h5>
            <div className="card-body home home-info">
                {(select === "Portal Info" && <PortalInfo/>) ||
                    (select === "Release Info" && <ReleaseInfo/>) ||
                    <PortalInfo/>
                }
            </div>
        </div>
    );
}

export default Home;