import React, { useState, useEffect } from "react";
import Success from "./Success";
import Protection from "./Protection";
import ErrorBody from "../../common/error/ErrorBody";
import Loading from "../../common/loading/Loading";
import axios from "../../../api/management_api";

function Resourse(props) {
  const [sel, setSel] = useState("Protection");
  const [msg, setMsg] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setSel("Protection");
    setMsg();
    setError();
  }, [props]);

  const changeResource = (opt) => {
    setSel(opt);
    setMsg();
    setError();
    if (opt === "Update All") {
      axios
        .post("/v1/catalog/updateAllVendorResources")
        .then((res) => setMsg(res.data.statusMessage))
        .catch((err) => setError(err.message));
    }
  };

  return (
    <div className="card">
      <h5 className="card-header">Vendor Resource</h5>
      <div className="card-body">
        <h5 className="card-title">
          <button
            className={
              sel === "Protection"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeResource("Protection")}
          >
            Protection
          </button>
          <button
            className={
              sel === "Update All"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeResource("Update All")}
          >
            Update All
          </button>
        </h5>

        <div style={{ border: "2px solid #666", borderRadius: "3px" }}>
          {sel === "Protection" && <Protection />}
          {sel === "Update All" &&
            (msg ? (
              <Success message={msg} />
            ) : error ? (
              <ErrorBody errorMessage={error} />
            ) : (
              <Loading />
            ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Resourse);
