/*
export const outhUrl = () => {
    let urlPath = window.location.href;
    if (urlPath.startsWith('https://dev-nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fedtest.tdk.dk',
            ping_redirect: 'https://dev-nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.startsWith('https://tp-test-nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fedtest.tdk.dk',
            ping_redirect: 'https://tp-test-nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.startsWith('https://tp-pet-nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fed.pp.tdc.dk',
            ping_redirect: 'https://tp-pet-nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.startsWith('https://test-nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fed.pp.tdc.dk',
            ping_redirect: 'https://test-nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.startsWith('https://nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fed.tdc.dk',
            ping_redirect: 'https://nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.startsWith('https://preprod-nuu-visitation-webadmin')) {
        return {
            ping_authority: 'https://fed.pp.tdc.dk',
            ping_redirect: 'https://preprod-nuu-visitation-webadmin.knet.lqd.dk/signin-oidc'
        }
    } else if (urlPath.includes('http://localhost')) {
        return {
            //ping_authority: 'https://login.microsoftonline.com/c95a25de-f20a-4216-bc84-99694442c1b5',
            ping_authority: 'https://login.microsoftonline.com/e8dcf6e6-3acc-4af9-9cb2-77f688cb688b',
            ping_redirect: 'http://localhost:3000/home'
        }
    }
}
*/

export const getToken = () => {
    console.log(sessionStorage.getItem('accessToken'));
    return sessionStorage.getItem('accessToken');
    //return sessionStorage.getItem(`${outhUrl().ping_authority}_W_secret`);
}
