import React, { useEffect, useState } from 'react';
import AuthAxios, { hasAccess } from '../../../api/management_api';
import Loading from '../../../components/common/loading/Loading';
import ErrorBody from '../../../components/common/error/ErrorBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Notify from '../../management/Notify';

export default function ServiceCatalog() {
  const [error, setError] = useState('');
  const [load, setLoad] = useState(false);
  const [services, setServices] = useState([]);
  const [data, setData] = useState();
  const [selectService, setSelectService] = useState('');
  const [selectTech, setSelectTech] = useState('');
  const [techs, setTechs] = useState([]);
  const access = useState(hasAccess())[0];

  //new - add
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState([]);
  const [notifyData, setNotifyData] = useState({
    title: '',
    method: ''
  });

  useEffect(() => {
    setError('');
    setLoad(true);
    AuthAxios.get('/v1/catalog/getAllCatalogEntries').then(res => {
      if (res.data) {
        setData(res.data);
        const keys = Object.keys(res.data);
        setServices(keys);
        setSelectService(keys[0]);
      } else {
        setError('No Data Found!');
      }
      setLoad(false);
    }).catch(err => {
      setError(err.message);
      setLoad(false);
    })
  }, []);

  useEffect(() => {
    setEdit(false);
    setFormData([]);
    if (data && selectService) {
      setTechs(data[selectService].map((tech) => {
        return {
          technology: tech.technology,
          services: JSON.parse(tech.resourceSchema)
        };
      }));
      setSelectTech(pre => pre ? pre : data[selectService][0].technology);
    }
  }, [selectService, data]);

  const selectServiceHandler = (ser) => {
    setSelectService(ser);
    setSelectTech('');
  }

  const onChangeTech = (techValue) => {
    setSelectTech(techValue);
    setEdit(false);
    setFormData([]);
  }

  //Edit
  const editService = () => {
    setEdit(true);
    setFormData(techs.find(t => selectTech === t.technology)?.services.map((s, idx) => ({ id: idx, ...s })));
  }

  const cancelEdit = () => {
    setEdit(false);
    setFormData([]);
  }

  const addNew = () => {
    const id = new Date().getTime();
    setFormData(pre => [{ id: id, serviceName: '', serviceType: '' }, ...pre]);
  }

  const deleteHandler = (id) => {
    setFormData(pre => pre.filter(s => s.id !== id));
  }

  const onChangeField = (e, id) => {
    setFormData(pre => pre.map(s => s.id === id ? { ...s, [e.target.name]: e.target.value } : s));
  }

  //save edit 
  const saveHandler = () => {
    setNotifyData({ title: `Do you want to save?`, method: saveService });
  }

  const saveService = () => {
    setNotifyData({ title: `Waiting for update.`, method: '' });
    const copyTechs = [...techs];
    let reqData = {};
    copyTechs.forEach(t => {
      reqData[t.technology] = t.technology === selectTech ?
        formData.map(s => ({ serviceName: s.serviceName, serviceType: s.serviceType }))
        : t.services;
    });
    AuthAxios.post(`/v1/catalog/createNewCFSCatalog?domain=${selectService}`, reqData)
      .then(res => {
        updateSuccessData(reqData);
        setNotifyData({ title: ` Successfully updated.`, method: '' });
      }).catch(err => {
        setNotifyData({ title: `Fail to update. Something went wrong!`, method: '' });
      });
  }

  //update success data
  const updateSuccessData = (reqData) => {
    let res = {};
    Object.assign(res, data);
    res[selectService].forEach(s => {
      if (s.technology === selectTech) {
        s.resourceSchema = JSON.stringify(reqData[selectTech])
      }
    });
    setData(res);
  }


  return (
    <div className="card">
      <Notify notifyData={notifyData} />
      <h5 className="card-header">Service Catalog</h5>
      <div className="card-body">
        {load ? <Loading /> : <>
          <h5 className="card-title">
            {services?.length > 0 && services.map((ser, index) => {
              return <button key={index} className={selectService === ser ? 'btn btn-secondary m-1 px-5 ' : 'btn btn-info m-1 px-5 '} type="button" onClick={() => selectServiceHandler(ser)} >{ser}</button>
            })}
          </h5>
          <div className='p-2' style={{ border: '1px solid rgb(204 213 218)', borderRadius: '3px' }}>

            {error && <ErrorBody errorMessage={error} />}

            {techs?.length > 0 && techs.map((tech, index) => {
              return <button key={index} className={selectTech === tech.technology ? 'btn btn-secondary m-1 px-5 ' : 'btn btn-info m-1 px-5 '} type="button" onClick={() => onChangeTech(tech.technology)} >{tech.technology}</button>
            })}

            {access && techs?.length > 0 && <>
              {edit ?
                <div style={{ display: 'inline-flex', float: 'right' }}>
                  <button className="btn btn-sm btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                    title='save' onClick={() => saveHandler()}><FontAwesomeIcon icon={faSave} /> Save</button>
                  <button className="btn btn-sm btn-danger" title='cancel' onClick={() => cancelEdit()}><FontAwesomeIcon icon={faTimes} /> Cancel</button>
                </div>
                :
                <button className='btn btn-sm btn-info float-right' onClick={editService}><FontAwesomeIcon icon={faEdit} /> Edit</button>
              }
            </>}

            {selectTech && <div className="div-table table-responsive p-2" style={{ maxHeight: "70vh" }}>
              <table className="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Service Type</th>
                    {access && edit && <th className='text-center'>
                      <button className="btn btn-sm btn-success" title='add' onClick={() => addNew()} >
                        <FontAwesomeIcon icon={faPlusSquare} /></button>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {access && edit ?
                    formData?.length > 0 && formData.map(s => <tr key={s.id}>
                      <td>
                        <input type='text' className='form-control' name="serviceName" maxLength={30}
                          value={s.serviceName} onChange={(e) => onChangeField(e, s.id)} />
                      </td>
                      <td>
                        <input type='text' className='form-control' name="serviceType" maxLength={30}
                          value={s.serviceType} onChange={(e) => onChangeField(e, s.id)} />
                      </td>
                      <td style={{ textAlign: 'center' }}><button className="btn btn-sm btn-outline-danger" title='delete'
                        onClick={() => deleteHandler(s.id)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                    </tr>)
                    :
                    techs.find(t => selectTech === t.technology)?.services.map((s, index) => <tr key={index}>
                      <td>{s.serviceName}</td>
                      <td>{s.serviceType}</td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>}
          </div>
        </>}
      </div>
    </div>
  )
}
