import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ErrorBody from '../../common/error/ErrorBody';
import Loading from '../../common/loading/Loading';
import Notify from '../Notify';
import axios, { hasAccess } from '../../../api/management_api';


function QosTable() {
    const [data, setData] = useState();
    const [edit, setEdit] = useState({ id: '' });
    const [notifyData, setNotifyData] = useState({ title: '' })
    const [error, setError] = useState();
    const access = useState(hasAccess())[0];

    useEffect(() => {
        refreshData();
    }, [])

    const refreshData = () => {
        axios.get('/v1/qos/getAllQosClassDefinitions')
            .then(res => {
                setData(res.data);
                if(res.data.length===0){
                    setError("No data found");
                }
            })
            .catch(err => {
                setError(err.message);
            })
    }


    const formChange = (e) => {
        setEdit({ ...edit, [e.target.name]: e.target.value });
    }

    const clickSave = () => {
        setNotifyData({ title: `Want to save ${edit.productName} ?`, method: performSave })
    }
    const performSave = () => {
         setNotifyData({title:`trying to save ${edit.productName} !`,method:''})
        axios.post('/v1/qos/createBulkQosClassDefinitions',[edit]).then(res=>{
            refreshData();
            setNotifyData({title:`Successfully saved ${edit.productName} !`,method:''});
            setEdit({id:''});
        }).catch(err=>{
            setNotifyData({title:err.message,method:''});
            setEdit({id:''});
        })
    }

    const addNew = () => {
        let newData = {
            id: 0,
            productName: "",
            quality: "",
            serviceSpeed: "",
            qos: "",
            trafficType: "",
            serviceType: "",
            bandwidthValue: ""
        }
        setEdit(newData);
    }

    const deleteClick = () => {
        setNotifyData({ title: `Want to delete ${edit.productName} ?`, method: performDeleteClick })
    }

    const performDeleteClick = () => {
        setNotifyData({ title: `Trying to delete ${edit.productName} !`, method: '' });
        axios.delete(`/v1/qos/deleteQosClassDefinition?qosClassDefinitionId=${edit.id}`).then(res=>{
            refreshData();
            setNotifyData({title:`Successfully deleted ${edit.productName} !`,method:''});
            setEdit({id:''});
        }).catch(err=>{
            setNotifyData({title:err.message,method:''});
            setEdit({id:''});
        })
    }

    return (
        <div className="table-responsive" style={{maxHeight:"70vh"}}>
            <Notify notifyData={notifyData} />
            {error ? <ErrorBody errorMessage={error} /> :
                <table className="table table-bordered table-striped table-sm">
                    <thead >
                        <tr>
                            {access && <th><button className="btn btn-sm btn-success" onClick={() => addNew()} disabled={edit.id === 0 ? true : false}>
                                <FontAwesomeIcon icon={faPlusSquare} /></button></th>}
                            <th>Product Name</th>
                            <th>Quality</th>
                            <th>Service Speed</th>
                            <th>Qos</th>
                            <th>Traffic Type</th>
                            <th>Service Type</th>
                            <th>Bandwidth</th>

                        </tr>
                    </thead>
                    <tbody>
                        { //create new
                            edit.id === 0 &&
                            <tr>
                                <td><div style={{ display: 'inline-flex' }}>
                                    <button className="btn btn-sm btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                        disabled={edit.productName ? false : true} onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                    <button className="btn btn-sm btn-danger" onClick={() => setEdit({ id: '' })}><FontAwesomeIcon icon={faTimes} /></button>
                                </div></td>
                                <td><input type="text" className="form-control form-control-sm"
                                    name='productName' value={edit.productName} onChange={(e) => formChange(e)}
                                /></td>
                                <td><input type="text" className="form-control form-control-sm" name="quality"
                                    value={edit.quality} onChange={(e) => formChange(e)} /></td>
                                <td><select className="form-control form-control-sm" name="serviceSpeed"
                                    value={edit.serviceSpeed} onChange={(e) => formChange(e)}>
                                    <option value="">select</option>
                                    <option value="UP">UP</option>
                                    <option value="DOWN">DOWN</option>
                                </select>
                                </td>
                                <td>
                                    <select className="form-control form-control-sm" name="qos"
                                        value={edit.qos} onChange={(e) => formChange(e)}>
                                        <option value="">select</option>
                                        <option value="AFB">AFB</option>
                                        <option value="AFNB">AFNB</option>
                                        <option value="BE">BE</option>
                                        <option value="EF">EF</option>
                                    </select></td>
                                <td>
                                    <select className="form-control form-control-sm" name="trafficType"
                                        value={edit.trafficType} onChange={(e) => formChange(e)}>
                                        <option value="">select</option>
                                        <option value="MULTICAST">MULTICAST</option>
                                        <option value="UNICAST">UNICAST</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control form-control-sm" name="serviceType"
                                        value={edit.serviceType} onChange={(e) => formChange(e)}>
                                        <option value="">select</option>
                                        <option value="CORE">CORE</option>
                                        <option value="NUUDAY">NUUDAY</option>
                                    </select>
                                </td>
                                <td><input type="text" className="form-control form-control-sm" name="bandwidthValue"
                                    value={edit.bandwidthValue} onChange={(e) => formChange(e)} /></td>
                            </tr>}

                        { //existing
                            data ? data.map((item, index) => <tr key={index}>
                                {edit.id === item.id ? <><td><div style={{ display: 'inline-flex' }}>
                                    <button className="btn btn-sm btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                        disabled={edit.productName ? false : true} onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                    <button className="btn btn-sm btn-danger" onClick={() => setEdit({ id: '' })}><FontAwesomeIcon icon={faTimes} /></button>
                                </div></td>

                                    <td><input type="text" className="form-control form-control-sm"
                                        name='productName' value={edit.productName} onChange={(e) => formChange(e)}
                                    /></td>
                                    <td><input type="text" className="form-control form-control-sm" name="quality"
                                        value={edit.quality} onChange={(e) => formChange(e)} /></td>
                                    <td><select className="form-control form-control-sm" name="serviceSpeed"
                                        value={edit.serviceSpeed} onChange={(e) => formChange(e)}>
                                        <option value="">select</option>
                                        <option value="UP">UP</option>
                                        <option value="DOWN">DOWN</option>
                                    </select>
                                    </td>
                                    <td>
                                        <select className="form-control form-control-sm" name="qos"
                                            value={edit.qos} onChange={(e) => formChange(e)}>
                                            <option value="">select</option>
                                            <option value="AFB">AFB</option>
                                            <option value="AFNB">AFNB</option>
                                            <option value="BE">BE</option>
                                            <option value="EF">EF</option>
                                        </select></td>
                                    <td>
                                        <select className="form-control form-control-sm" name="trafficType"
                                            value={edit.trafficType} onChange={(e) => formChange(e)}>
                                            <option value="">select</option>
                                            <option value="MULTICAST">MULTICAST</option>
                                            <option value="UNICAST">UNICAST</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-control form-control-sm" name="serviceType"
                                            value={edit.serviceType} onChange={(e) => formChange(e)}>
                                            <option value="">select</option>
                                            <option value="CORE">CORE</option>
                                            <option value="NUUDAY">NUUDAY</option>
                                        </select>
                                    </td>
                                    <td><input type="text" className="form-control form-control-sm" name="bandwidthValue"
                                        value={edit.bandwidthValue} onChange={(e) => formChange(e)} /></td>
                                </>
                                    :
                                    <>
                                        {access ? <td> <button className="btn btn-sm btn-info"
                                            onClick={() => setEdit(item)}><FontAwesomeIcon icon={faEdit} /></button></td> : null}
                                        <td>{item.productName}</td>
                                        <td>{item.quality}</td>
                                        <td>{item.serviceSpeed}</td>
                                        <td>{item.qos}</td>
                                        <td>{item.trafficType}</td>
                                        <td>{item.serviceType}</td>
                                        <td>{item.bandwidthValue}</td>
                                    </>
                                }
                                {edit.id !== 0 && edit.id === item.id && <td style={{ textAlign: 'center' }}><button className="btn btn-sm btn-outline-danger"
                                    data-toggle="modal" data-target="#notifyModal"
                                    onClick={() => deleteClick()}><FontAwesomeIcon icon={faTrashAlt} /></button></td>}
                            </tr>
                            ) : <tr style={{ background: '#fff' }}><td colSpan="8"><Loading /></td></tr>}
                    </tbody>
                </table>}
        </div>
    );
}

export default React.memo(QosTable);