import React from 'react';
import NuudayImg from '../asset/image/nuuday-1.png'
import '../asset/css/login.css';
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../api/msalconfig";

function handleLogin(instance, accounts) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });


}

export default function Login() {

    const {instance, accounts} = useMsal();

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="d-flex justify-content-center h-100">
                    <div className="card login-card">
                        <div className="card-header">
                            <h3>Sign In</h3>
                            <div className="logo-image"></div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <button className="btn btn-block login_btn"
                                        onClick={() => handleLogin(instance, accounts)}>Nuu
                                    Visitation - Web Admin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
