import React from 'react';

function StagingModal({ stagingData }) {

    return (
        <table className="table table-striped table-sm table-bordered " style={{ textAlign: "left" }}>
            <tbody>
                {stagingData && Object.keys(stagingData).map((k, i) => <tr key={i}>
                    <td>{k}</td>
                    <td>{stagingData[k].toString()}</td>
                </tr>)}
            </tbody>
        </table>
    );
}

export default React.memo(StagingModal);