import React, { useState, useEffect } from 'react';
import ErrorBody from '../../common/error/ErrorBody';
import Loading from '../../common/loading/Loading';
import axios from '../../../api/management_api';


function OpennetSpeed() {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    refreshData();
  }, [])

  const refreshData = () => {
    setLoad(true);
    setData([]);
    axios.get('/v1/opennetmaxspeed/')
      .then(res => {
        if (res.data.length === 0) {
          setError("No data found");
        } else {
          setData(res.data);
        }
        setLoad(false);
      })
      .catch(err => {
        setError(err.message);
        setLoad(false);
      })
  }

  return (
    <div className="table-responsive" style={{ maxHeight: "70vh" }}>
      {error ? <ErrorBody errorMessage={error} /> :
        <table className="table table-bordered table-striped table-sm">
          <thead >
            <tr>
              <th>Calculation Method</th>
              <th>Services</th>
              <th>Vendor Max Speed</th>
              <th>Speed Mode</th>
            </tr>
          </thead>
          <tbody>
            {load ? <tr>
              <td colSpan="4"><Loading /></td>
            </tr>
              : data.map((d, i) => <tr key={i}>
                <td>{d.calculationMethod}</td>
                <td>{d.services}</td>
                <td>{d.vendorMaxSpeed}</td>
                <td>{d.speedMode}</td>
              </tr>)
            }

          </tbody>
        </table>}
    </div>
  );
}

export default React.memo(OpennetSpeed);