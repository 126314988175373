import React from 'react';
import Loading from '../loading/Loading';
import '../../../asset/css/modal.css';

function Modal(props) {
  return (
    <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content modalContainer">
          <div className="modal-header">
            <h6 className="modal-title" id="exampleModalLabel">{props.title}</h6>
            <span style={{ cursor: "pointer" }} className="close" data-dismiss="modal" aria-label="Close">
              x
              </span>
          </div>
          <div className="modal-body">
            {props.error ?
              props.error
              :
              props.res ?
                props.res
                :
                <Loading />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Modal);