import React from "react";

function SigmaMacSpeed({ macSpeed, sel }) {
  return (
    <table className="table table-striped table-sm table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Values</th>
        </tr>
      </thead>
      <tbody>
        {macSpeed.sort((c, d) => c.description < d.description ? 1 : -1).map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ whiteSpace: "nowrap" }}>
                {sel === "MAC" &&
                  item.characteristic.name.replaceAll("_MAC", " ")}
                {sel === "SPEED" &&
                  item.characteristic.name.replaceAll("_SPEEDS_", " ")}
              </td>
              <td>
                <div className="row mx-1">
                  {item.characteristic.characteristicCharValue
                    .map((data, index) => {
                      if (sel === "MAC") {
                        return (
                          <div
                            key={index}
                            val={data.value.replace("MAC", "")}
                            className="col-md-2 sigma-cell"
                          >
                            {data.value.replace("MAC", "")}
                          </div>
                        );
                      } else if (sel === "SPEED") {
                        return (
                          <div
                            key={index}
                            val={data.value.replace("SPEED", "") + "000"}
                            className="col-md-2 sigma-cell"
                          >
                            {data.value.split("_").join("000/") + "000"}
                          </div>
                        );
                      } else {
                        return 0;
                      }
                    })
                    .sort((a, b) => a.props.val - b.props.val)}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(SigmaMacSpeed);
