import React, { useState } from 'react';
import axios from '../../../api/management_api';
import ReactDataTable from './ReactDataTable';
import ErrorBody from '../../common/error/ErrorBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { requestType, vendors } from '../../../data/constant';

function History() {
    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        pageId: 0,
        searchTextRequest: "",
        searchTextResponse: "",
        serviceType: "",
        orderNumber: "",
        pageSize: "100",
        addressId: "",
        vendor: "",
        duration: "",
        greaterThan: "TRUE",
        requestId: "",
        primaryIdentifier: "",
        archive: false
    });

    const [error, setError] = useState();
    const [info, setInfo] = useState([]);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMad, setShowMad] = useState(false);
    const [madDetails, setMadDetails] = useState({
        houseNumber: "",
        houseLetter: "",
        side: "",
        floor: "",
        streetName: "",
        postCode: ""
    });

    const searchHistory = (e, pageNo) => {
        e.preventDefault();
        setError();
        setShowMad(false);
        setLoad(true);
        setInfo([]);
        setFormData(pre => ({ ...pre, pageId: pageNo }));
        axios.post(`/v1/requestHistory/getRequestHistoryByDate`, {
            ...formData,
            pageId: pageNo
        }).then(res => {
            setLoad(false);
            if (res.data.length > 0) {
                setInfo(res.data);
            } else {
                setError("No Data Found");
            }

        }).catch(err => {
            setLoad(false);
            setError(err.message);
        });

    }

    const resetHistory = () => {
        setFormData({
            startDate: "",
            endDate: "",
            pageId: 0,
            searchTextRequest: "",
            searchTextResponse: "",
            serviceType: "",
            orderNumber: "",
            pageSize: "100",
            addressId: "",
            vendor: "",
            duration: "",
            greaterThan: "TRUE",
            requestId: "",
            primaryIdentifier: "",
            archive: false
        });
        resetMad();
        setShowMad(false);
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData(pre => ({ ...pre, [name]: value }));
    }

    const resetMad = () => {
        setMadDetails({
            houseNumber: "",
            houseLetter: "",
            side: "",
            floor: "",
            streetName: "",
            postCode: ""
        });
    }

    const handleMad = (e) => {
        let name = e.target.name;
        let value = e.target.value.trim();
        setMadDetails(pre => ({ ...pre, [name]: value }));
    }

    // Handler for submit button click
    const findMad = () => {
        setLoading(true);
        axios.post("/v1/masterAddressCache/getMadIDbyAddress", madDetails)
            .then(res => {
                setFormData({ ...formData, addressId: res.data.addressSpecificId });
                setLoading(false);
            }).catch(() => {
                setFormData({ ...formData, addressId: "Not Found" });
                setLoading(false);
            });
    }


    return (
        <div>
            <div className="card">
                <h5 className="card-header">History Search</h5>
                <div className="div-table my-2">
                    <div className="form-row">
                        <div className="form-group col col-sm-3 col-md-3">
                            <label htmlFor="requestFromDate">Request from date</label>
                            <input type="datetime-local" className="form-control form-control-sm" name="startDate"
                                value={formData.startDate}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col col-sm-3 col-md-3">
                            <label htmlFor="requestToDate">Request to date</label>
                            <input type="datetime-local" className="form-control form-control-sm" name="endDate"
                                value={formData.endDate}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col col-sm-2 col-md-2">
                            <label htmlFor="request">Search by request</label>
                            <input type="text" className="form-control form-control-sm" name="searchTextRequest"
                                value={formData.searchTextRequest}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col col-sm-2 col-md-2">
                            <label htmlFor="response">Search by response</label>
                            <input type="text" className="form-control form-control-sm" name="searchTextResponse"
                                value={formData.searchTextResponse}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col col-sm-2 col-md-2">
                            <label htmlFor="response">Primary Idntifier</label>
                            <input type="text" className="form-control form-control-sm" name="primaryIdentifier"
                                value={formData.primaryIdentifier}
                                onChange={handleChange} />
                        </div>

                        <div className="form-group col col-sm-3 col-md-3">
                            <label htmlFor="response">Service Type</label>
                            <select className="form-control form-control-sm" name="serviceType"
                                value={formData.serviceType}
                                onChange={handleChange}>
                                <option value="">All</option>
                                {requestType.map((r, index) => <option key={index} value={r}>{r}</option>)}
                            </select>
                        </div>
                        <div className="form-group col col-sm-3 col-md-3">
                            <label htmlFor="response">Duration</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <select className="form-control form-control-sm" name="greaterThan"
                                        value={formData.greaterThan}
                                        onChange={handleChange}>
                                        <option value="TRUE">greater</option>
                                        <option value="FALSE">lesser</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control form-control-sm" name="duration"
                                    value={formData.duration}
                                    onChange={handleChange} />
                                <div className="input-group-append">
                                    <span className="input-group-text inputGroup-sizing-sm">ms</span>
                                </div>
                            </div>

                        </div>

                        <div className="form-group col-sm-2 col-md-2">
                            <label htmlFor="response">Vendor</label>
                            <select className="form-control form-control-sm" name="vendor"
                                value={formData.vendor} onChange={handleChange}>
                                <option value="">All</option>
                                {vendors.map((v, index) => <option key={index} value={v.name}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group col-sm-2 col-md-2">
                            <label htmlFor="response">Order Number</label>
                            <input type="text" className="form-control form-control-sm" name="orderNumber"
                                value={formData.orderNumber}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-2 col-md-2">
                            <label htmlFor="response">Request Id</label>
                            <input type="text" className="form-control form-control-sm" name="requestId"
                                value={formData.requestId}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-3 col-md-3">
                            <label htmlFor="address">Adress Id</label>
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" name="addressId"
                                    value={formData.addressId} onChange={handleChange} />
                                <div className="input-group-append" title={showMad ? "" : "find mad id by address"}>
                                    <button className="btn btn-sm btn-secondary" type="button"
                                        onClick={() => { setShowMad(pre => !pre); resetMad() }}>
                                        {showMad ? "Hide" : "Find"}</button>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-sm-2 col-md-2">
                            <label htmlFor="response">Records</label>
                            <select className="form-control form-control-sm" name="pageSize"
                                value={formData.pageSize}
                                onChange={handleChange}>
                                <option value="1">1</option>
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <div className="col-sm-7 col-md-7 mt-auto">
                            <div className="row">
                                <div className="form-group col-sm-4 col-md-4 text-left">
                                    <button type="submit" id="search-btn" className="btn btn-info"
                                        onClick={(e) => searchHistory(e, 0)}>Search</button>
                                    <button id="search-btn" className="btn btn-danger ml-2"
                                        onClick={() => resetHistory()}>Reset</button>
                                </div>
                                <div className="form-group col-sm-4 col-md-4">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="archive"
                                            checked={formData.archive} onChange={() => setFormData({ ...formData, archive: !formData.archive })} />
                                        <label className="custom-control-label pt-1" htmlFor="archive">Search from archive</label>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4 col-md-4 text-right">
                                    <button id="search-btn" className="btn btn-info "
                                        disabled={formData.pageId <= 0}
                                        onClick={(e) => searchHistory(e, formData.pageId - 1)}
                                    >&lt;</button>
                                    <i>Page: {formData.pageId} </i>
                                    <button id="search-btn" className="btn btn-info ml-2"
                                        disabled={info.length < formData.pageSize}
                                        onClick={(e) => searchHistory(e, formData.pageId + 1)}
                                    >&gt;</button>
                                </div>
                            </div>
                        </div>
                        {showMad && <div className="div-table col-sm-12 col-md-12 mx-auto" style={{ background: "#f0f1f375" }}>
                            <div className="form-row">
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="madId">Street Name</label>
                                    <input type="text" className="form-control form-control-sm" name="streetName"
                                        value={madDetails.streetName} onChange={handleMad} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">House no</label>
                                    <input type="text" className="form-control form-control-sm" name="houseNumber"
                                        value={madDetails.houseNumber} onChange={handleMad} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">House letter</label>
                                    <input type="text" className="form-control form-control-sm" name="houseLetter"
                                        value={madDetails.houseLetter} onChange={handleMad} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Floor</label>
                                    <input type="text" className="form-control form-control-sm" name="floor"
                                        value={madDetails.floor} onChange={handleMad} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Side</label>
                                    <input type="text" className="form-control form-control-sm" name="side"
                                        value={madDetails.side} onChange={handleMad} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Post Code</label>
                                    <input type="text" className="form-control form-control-sm" name="postCode"
                                        value={madDetails.postCode} onChange={handleMad} />
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="col-sm" style={{ textAlign: "center" }}>
                                    <button type="submit" className="btn btn-secondary ml-2"
                                        onClick={() => findMad()} disabled={loading}>{loading ? "Wait" : "Get MadId"}</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="div-table mb-3">
                    {error ? <ErrorBody errorMessage={error} /> :
                        info.length > 0 ? <ReactDataTable rowData={info} archive={formData.archive} /> :
                            <div className="text-center p-4 text-wrap">
                                <FontAwesomeIcon className="mb-3" icon={faSearch} size="5x" color="rgb(87, 179, 193)"
                                    spin={load ? true : false} />
                                <h2 style={{ color: "rgb(73, 80, 87)" }}>
                                    {load ? "Loading" : "Search for History"}</h2>
                            </div>}
                </div>
            </div>

        </div>

    );
}

export default History;