import React from 'react';
import { requestType } from '../../../data/constant';

export const ReactTableFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
        <span className="mx-auto pt-2">
            {column.Header === "Type" ?
                <select className="form-control form-control-sm" style={{ minWidth: "70px" }} value={filterValue || ''} onChange={(e) => setFilter(e.target.value)}>
                    <option value="">All</option>
                    {requestType.map((rType,index) => <option key={index}>{rType}</option>)}
                </select>
                :
                <input className="form-control form-control-sm" style={{ minWidth: "70px" }} value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} />
            }

        </span>
    )
}