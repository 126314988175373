export const releaseData = [
  {
    version: "v1.1.3",
    date: "2022-08-04",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add Release info in home",
      "Add schduler on request history",
      "Add delete functionality for specific cache",
      "Add service environment info",
      "Add Staging inventory tab",
      "Add archive functionality on request history",
      "Add max speed"
    ],
    change: [
      "Change loading view",
      "Modify home view",
      "Modify cache view",
      "Extend ai table with change date, nam id"
    ],
    fix: [
      "Fix text wrap in property table",
      "Fix speed view in hansen",
      "Fix xml view",
      "Fix floor/side position in mad search"
    ]
  },
  {
    version: "v1.1.2",
    date: "2022-06-27",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add Security headers",
      "Add timeout for 5 min",
      "Add new env"
    ],
    change: [
      "Upgrade react",
      "Upgrade dependencies",
      "Change route config"
    ],
    fix: [
      "Fix security vulnerabilities",
      "Fix authorization",
      "Fix test execution",
      "Fix css"
    ]
  },
  {
    version: "v1.1.1",
    date: "2022-03-07",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [],
    change: [
      "Change inventory mapping",
      "Remove console log"
    ],
    fix: [
      "Fix test endpoints"
    ]
  },
  {
    version: "v1.1.0",
    date: "2022-01-13",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add Qos profile"
    ],
    change: [
      "Extend AI search"
    ],
    fix: [
      "Show default mad search in AI"
    ]
  },
  {
    version: "v1.0.0",
    date: "2021-12-02",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add  Access inventory",
      "Add image from docker cache",
      "Add version",
      "Add CPE in management",
      "Add Prequalification v2"
    ],
    change: [
      "Format readme file",
      "Create server side search and pagination for history",
      "Change UI color",
      "Change date format",
      "Change encoding in history modal"
    ],
    fix: [
      "Show default mad search in AI",
      "Fixed single retry in transaction details",
      "Update package"
    ]
  },
  {
    version: "v0.6.0-beta",
    date: "2021-08-10",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add nuuday font",
      "Add speed calculation v2",
      "Add hansen service catalog",
      "Add json copy feature in json-view",
      "Extend history search",
      "Add OSSCHAC_NAL"
    ],
    change: [
      "Change css"
    ],
    fix: [
      "Update package",
      "Fixed jSON view"
    ]
  },
  {
    version: "v0.5.0-beta",
    date: "2021-03-18",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add Authorization",
      "Add https",
      "Add 2nd time token exchange",
      "Setup backend connection with token",
      "Setup Authentication and authorization using Ping",
      "Add Services endpoints in test section",
      "Add history section",
    ],
    change: [],
    fix: []
  },
  {
    version: "v0.4.0-beta",
    date: "2021-01-28",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Create Authorization",
      "Build logic for test execution",
      "Enable production environment",
      "Setup backend connection with token",
      "Setup Authentication and authorization using Ping",
      "Add commercial speed",
      "Add resource protection",
      "Add whitelist file view",
      "Add cache management"
    ],
    change: [
      "Modify nginx",
      "Optimize code",
      "Reconfig drone"
    ],
    fix: []
  },
  {
    version: "v0.3.0-beta",
    date: "2020-12-08",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add property management",
      "Add QOS management",
      "Add dynamic env identifer for backend connection",
      "Create ci/cd for test and dev",
      "Add Sigma CFS",
      "Add resource Section",
      "Add managment section",
      "Add cache management"
    ],
    change: [
      "Refactore code"
    ],
    fix: [
      "Fix resource version lock",
      "Fix modal issue"
    ]
  },
  {
    version: "v0.2.0-beta",
    date: "2020-11-06",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Add execution test section",
      "Add error handling",
      "Add loading effect",
      "Include docker file"
    ],
    change: [],
    fix: []
  },
  {
    version: "v0.1.0-beta",
    date: "2020-11-04",
    by: "Aloke Basak",
    contact: "alob@nuuday.dk",
    add: [
      "Ceate react app",
      "Create git setup",
      "Build basic template"
    ],
    change: [],
    fix: []
  }
]