import React, { useState, useEffect } from 'react';
import Modal from '../common/modal/Modal';
import { urls } from '../../api/test_service_api';
import ErrorBody from '../common/error/ErrorBody';
import Execution from './Execution';
import axios from 'axios';
import { getToken } from '../../api/outhApi';

function ServiceExecution(props) {
    const [service, setService] = useState('Select service');
    const [method, setMethod] = useState('POST');
    const [body, setBody] = useState('');
    const [error, setError] = useState('');
    const [modelTitle, setModalTitle] = useState('Execution Processing...');
    const [reply, setReply] = useState();
    const [content, setContent] = useState('application/xml');
    const [accept, setAccept] = useState('application/xml');
    const [qualification, setQualification] = useState('');
    const [address, setAddress] = useState('');


    useEffect(() => {
        setService('Select service');
        setBody('');
    }, [props])

    const changeService = (ser) => {
        setService(ser);
        setBody('');
        setAddress('');
        setQualification('');
        if (ser !== 'Select service') {
            setMethod(urls[ser].method);
            setContent(urls[ser].content);
            setAccept(urls[ser].accept)
        }
    }

    const handleAddress = (e) => {
        setAddress(e.target.value.trim());
    }

    const execute = (e) => {
        setError('');
        setModalTitle('Execution Process');
        setReply();
        if (service === 'serviceQualification(TMF)' || service === 'serviceQualification(legacy)')
        {
            const url = urls[service].url;
            let modifiedURL = service === 'serviceQualification(TMF)' ? `${url}?${qualification}=${address}` : url
            axios.get(modifiedURL, { headers: { 'Content-Type': content, 'accept': accept, 'Authorization': `Bearer ${getToken()}` } })
                .then(res => {
                    setError('');
                    setModalTitle('Execution Succeeded');
                    setReply({ resType: accept, reqType: content, req: body, reply: res.data });
                })
                .catch(err => {
                    setModalTitle('Execution failed');
                    setError(err.message);
                })
        } else {
            axios.post(urls[service].url,
                content === 'application/xml' ?
                    body : JSON.parse(body), { headers: { 'Content-Type': content, 'accept': accept, 'Authorization': `Bearer ${getToken()}` } })
                .then(res => {
                    setError('');
                    setModalTitle('Execution Succeeded');
                    setReply({ resType: accept, reqType: content, req: body, reply: res.data });
                })
                .catch(err => {
                    setModalTitle('Execution failed');
                    setError(err.message);
                })
        }
    }

    const handleServiceQuificationCheck = () => {
        if (service === 'serviceQualification(TMF)') {
            return (address !== '');
        }
        return (service !== 'Select service');
    }

    return (
        <>
            <Modal id='execute' title={modelTitle}
                res={reply ? <Execution exec={reply} /> : ''}
                error={error ? <ErrorBody errorMessage={error} /> : ''} />

            <div className="card">
                <h5 className="card-header">Visitation Services</h5>
                <div className="card-body">
                    <div className="row">
                        <div className="col-2">
                            <label htmlFor="method">Method</label>
                            <select className="form-control input-sm"
                                style={{ backgroundColor: '#e6eef2', fontSize: 'inherit' }}
                                value={method}
                                onChange={(e) => setMethod(e.target.value)}>
                                <option defaultValue>POST</option>
                                <option >GET</option>
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="service">Service</label>
                            <select className="form-control input-sm"
                                style={service === 'Select service' ? { backgroundColor: '#ffffff', fontSize: 'inherit' } :
                                    { backgroundColor: '#e6eef2', fontSize: 'inherit' }}
                                value={service}
                                onChange={(e) => changeService(e.target.value)}>
                                <option defaultValue>Select service</option>
                                {Object.keys(urls).map(service => <option key={service}>{service}</option>)}
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="content">Content Type</label>
                            <select className="form-control input-sm"
                                value={content}
                                style={{ backgroundColor: '#e6eef2', fontSize: 'inherit' }}
                                onChange={(e) => { setContent(e.target.value) }}>
                                <option value='application/xml'>application/xml</option>
                                <option value='application/json'>application/json</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="accept">Accept Type</label>
                            <select className="form-control input-sm"
                                value={accept}
                                style={{ backgroundColor: '#e6eef2', fontSize: 'inherit' }}
                                onChange={(e) => { setAccept(e.target.value) }}>
                                <option value='application/xml'>application/xml</option>
                                <option value='application/json'>application/json</option>
                            </select>
                        </div>
                    </div>
                    {/* Show the fields for serviceQualification(TMF) */}
                    {
                        service === "serviceQualification(TMF)" &&
                        <div className='service-qualification-fields'>
                            <div className='row radio-buttons'>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                      type="radio"
                                      id="customRadioInline1"
                                      name="service-qualification-type"
                                      className="custom-control-input"
                                      onChange={() => {setAddress('');setQualification("addressid")}} />
                                    <label className="custom-control-label" htmlFor="customRadioInline1">
                                      By NAM Id
                                    </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="service-qualification-type"
                                      className="custom-control-input"
                                      onChange={() => {setQualification("id"); setAddress('')}} />
                                    <label className="custom-control-label" htmlFor="customRadioInline2">By Access Inventory Id</label>
                                </div>
                            </div>
                            {
                                qualification !== '' &&
                                <div className="form-group col-4 input" style={{ paddingLeft: '0'}}>
                                    <label htmlFor="">Enter Id value</label>
                                    <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={address}
                                    onChange={handleAddress}/>
                                </div>
                            }
                        </div>
                    }
                    <hr />
                    <div className="form-group">
                        <textarea className="form-control" id="xml" rows="11"
                            disabled={method === "GET" ? true : false}
                            value={body}
                            placeholder="Request body"
                            onChange={(e) => setBody(e.target.value)}></textarea>
                    </div>
                    <button
                      type="submit"
                      data-toggle="modal"
                      data-target={`#execute`}
                      className="btn btn-secondary pl-5 pr-5"
                      disabled={!(service && ((method === 'POST' && body.trim()) || method === 'GET') && handleServiceQuificationCheck() )}
                      onClick={(e) => execute(e)}>
                        Execute
                    </button>
                </div>
            </div>
        </>
    );
}

export default ServiceExecution;
