import React, { useEffect, useState } from 'react';
import Loading from '../../common/loading/Loading';
import ErrorBody from '../../common/error/ErrorBody';
import axios, { catalogTech } from '../../../api/management_api';

function Catalog() {
    const [catalog, setCatalog] = useState();
    const [sel, setSel] = useState('H1');
    const [error, setError] = useState();

    useEffect(() => {
        axios.get('/v1/catalog/getCatalogEntries?technology=H1')
            .then(res => setCatalog(res.data[0]))
            .catch(err => setError(err.message))
    }, [])

    const changeCatalog = (tech) => {
        setCatalog();
        setError();
        setSel(tech);
        axios.get(`/v1/catalog/getCatalogEntries?technology=${tech}`)
            .then(res => setCatalog(res.data[0]))
            .catch(err => setError(err.message))
    }

    return (
        <div className="card">
            <h5 className="card-header">Catalog</h5>
            <div className="card-body">
                <h5 className="card-title">
                    {catalogTech.map((tech, index) => {
                        return <button key={index} className={sel === tech ? 'btn btn-secondary m-1 px-5 ' : 'btn btn-info m-1 px-5 '} type="button" onClick={() => changeCatalog(tech)} >{tech}</button>
                    })}
                </h5>
                <div style={{ border: '1px solid rgb(204 213 218)', borderRadius: '3px' }}>
                    {error ? <ErrorBody errorMessage={error} /> :
                        catalog ?
                            <table className="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>Selected</th>
                                        <th>{sel}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Id</td>
                                        <td>{catalog.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Technology</td>
                                        <td>{catalog.technology}</td>
                                    </tr>

                                    <tr>
                                        <td>Resource Schema</td>
                                        <td style={{ display: 'grid' }}>
                                            <div className="table-responsive">
                                                <table className="table table-bordered" style={{ background: '#fff' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ background: '#fff' }}>serviceName</th>
                                                            <th style={{ background: '#fff' }}>serviceType</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {JSON.parse(catalog.resourceSchema).map((data, index) => {
                                                            return <tr key={index}>
                                                                <td>{data.serviceName}</td>
                                                                <td>{data.serviceType}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Version</td>
                                        <td>{catalog.version}</td>
                                    </tr>
                                    <tr>
                                        <td>Locked</td>
                                        <td>{catalog.locked}</td>
                                    </tr>
                                </tbody>
                            </table>
                            : <Loading />}
                </div>
            </div>
        </div>
    );
}

export default Catalog;