import React from 'react';
import OpennetSpeed from './OpennetSpeed';

function MaxSpeed() {
    return (
        <div className="card">
            <h5 className="card-header">Max Speed</h5>
            <div className="card-body" style={{ display: 'grid' }}>
                <div className="div-table">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active h6" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Opennet</a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">

                        <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <OpennetSpeed />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default MaxSpeed;