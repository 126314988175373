import React from "react";

function Commercial({ comData }) {
  return (
    <table className="table table-bordered table-sm">
      <thead className="thead-light">
        <tr>
          <th>Name</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {comData.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ whiteSpace: "nowrap" }}>
                {item.characteristic.name
                  .replaceAll("_Commercial_Speed", " ")
                  .replaceAll("_", " ")}
              </td>
              <td>
                <table className="table table-striped table-bordered table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th>Service Up</th>
                      <th>Service Down</th>
                      <th>Technical Up(%)</th>
                      <th>Technical Down(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.characteristic.characteristicCharValue.map(
                      (item1, index) => (
                        <tr key={index}>
                          <td>{item1.value.split("-")[0] + "000"}</td>
                          <td>{item1.value.split("-")[1] + "000"}</td>
                          <td>{item1.value.split("-")[2]}</td>
                          <td>{item1.value.split("-")[3]}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(Commercial);
