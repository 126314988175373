import React, { useEffect, useState } from 'react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { UseAuthContext } from '../../context/AuthContext';
import {getToken} from '../../api/outhApi';
import './swagger.css'

export default function Swagger() {
    const requestInterceptor = (req) => {
        req.headers.Authorization = `Bearer ${getToken()}`;
        if (req.url.includes("http://")) {
           req.url = req.url.replace("http://", "https://")
        }
        return req;
    };

    return (
        <div className = "container-swagger">
            {getToken() && <SwaggerUI url= {process.env.REACT_APP_API_DOCS_URL}
                requestInterceptor={requestInterceptor} />}
        </div>
    )
}
