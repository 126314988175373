export const subscriberRequest = `<visitationSubscriberRequest>
  <requestInfo>
    <userId>M85688</userId>
    <trackingId>CUPO562:101847691</trackingId>
    <versionId>1.0</versionId>
    <companyId>Nuuday</companyId>
  </requestInfo>
  <visitationSubscriber>
    <customVisitationKey>101847691</customVisitationKey>
    <houseNo>10</houseNo>
    <letter />
    <floor>ST</floor>
    <side>TV</side>
    <appartment />
    <municipalityCode>461</municipalityCode>
    <streetCode>3995</streetCode>
    <addressOwner>Official</addressOwner>
    <infraStructureOwner>TDC</infraStructureOwner>
    <accessType />
    <installationId />
    <kapInfoRequired>true</kapInfoRequired>
    <netWorkCapacityCheck>true</netWorkCapacityCheck>
  </visitationSubscriber>
</visitationSubscriberRequest>`

export const subscriberReply = `<visitationSubscriberReply>
  <replyInfo>
    <replyTimestamp>2022-07-14 09:37:48</replyTimestamp>
    <statusCode>0</statusCode>
    <severityLevel>0</severityLevel>
    <statusMessage>OK</statusMessage>
    <transactionDuration>2831</transactionDuration>
    <serverId>nuu-visitation</serverId>
    <trackingId>CUPO562:101847691</trackingId>
  </replyInfo>
  <subscriberInfo>
    <alternativePOPName />
    <installationCode>3</installationCode>
    <fiberEnabled>true</fiberEnabled>
    <deselectDate />
    <deselectReason />
    <deselectExplanation />
    <infrastructureType />
    <infrastructureTypeExplanation />
    <diggingLength />
    <addressIndicator />
    <owner>TDC</owner>
    <kapInfo>
      <installationId />
      <externalKapID>G350027063</externalKapID>
      <installationPort />
    </kapInfo>
    <productType>ENERGIFYN</productType>
    <installationId>70219321</installationId>
    <networkCapacityCheck>
      <installationId>70219321</installationId>
      <leadTime>30</leadTime>
      <needIoAppointment />
      <validCpeFeature />
      <responseCode />
      <infrastructurStatus />
      <installationCode>3</installationCode>
      <priceCatagory />
      <leadTimeCalcDate>2022-07-14</leadTimeCalcDate>
      <productType>FibiaBSA</productType>
      <addressUid>0a3f50b4-20f4-32b8-e044-0003ba298018</addressUid>
      <capacityCheckObj />
    </networkCapacityCheck>
    <kapTypeId />
    <kapTypeName />
    <externalInstallationID />
    <plannedInstallationCode />
    <plannedDate />
    <mawisId />
    <earliestDeliveryDate>2022-07-15</earliestDeliveryDate>
    <technicianRequired>true</technicianRequired>
    <availableCapacity>true</availableCapacity>
  </subscriberInfo>
  <inventoryFetch>VSCACHE_NUUDAY_REAL_TIME</inventoryFetch>
</visitationSubscriberReply>`

export const addressRequest = `<visitationByAddressRequest>
<requestInfo>
  <userId>M93638</userId>
  <trackingId>CUPO6345:250906308   13.55.54.111173</trackingId>
  <versionId>1.0</versionId>
  <companyId>Nuuday</companyId>
</requestInfo>
<visitationByAddress>
  <customVisitationKey>250906308</customVisitationKey>
  <inventoryType>INCA</inventoryType>
  <lid>EF694927</lid>
  <zipCode />
  <locality />
  <streetName />
  <houseNo>4</houseNo>
  <letter />
  <floor />
  <side />
  <appartment />
  <municipalityCode>540</municipalityCode>
  <streetCode>2125</streetCode>
  <supportedCardTechnology>FIBER</supportedCardTechnology>
  <ispId />
  <byAddress />
  <useCurrentLid />
  <addressOwner>Official</addressOwner>
  <kapID>S210723838</kapID>
  <addressIndicator />
  <netWorkCapacityCheck>false</netWorkCapacityCheck>
</visitationByAddress>
</visitationByAddressRequest>`

export const addressReply = `<visitationByAddressReply>
<replyInfo>
  <replyTimestamp>2022-07-14 11:55:54</replyTimestamp>
  <statusCode>0</statusCode>
  <severityLevel>0</severityLevel>
  <statusMessage>OK</statusMessage>
  <transactionDuration>463</transactionDuration>
  <serverId>nuu-visitation</serverId>
  <trackingId>CUPO6345:250906308   13.55.54.111173</trackingId>
</replyInfo>
<customerServices>
  <customerNetService>
    <netServices>
      <serviceType>BroadbandService</serviceType>
      <serviceName>DHCPBusiness</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>TVoIPService</serviceType>
      <serviceName>TVBusiness</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>VoIPService</serviceType>
      <serviceName>VoIPBusiness</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>MPLSService</serviceType>
      <serviceName>MPLSVPN</serviceName>
      <ipVersionValue>
        <ipVersion>
          <ipVersion>IPv4</ipVersion>
          <ipVersion>IPv6</ipVersion>
        </ipVersion>
      </ipVersionValue>
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>BroadbandService</serviceType>
      <serviceName>DHCP</serviceName>
      <ipVersionValue>
        <ipVersion>
          <ipVersion>IPv4</ipVersion>
        </ipVersion>
      </ipVersionValue>
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>TVoIPService</serviceType>
      <serviceName>TV</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>VoIPService</serviceType>
      <serviceName>VoIP</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>BroadbandService</serviceType>
      <serviceName>StaticIP</serviceName>
      <ipVersionValue>
        <ipVersion>
          <ipVersion>IPv4</ipVersion>
          <ipVersion>IPv6</ipVersion>
        </ipVersion>
      </ipVersionValue>
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>MPLSService</serviceType>
      <serviceName>MPLSVPNResidential</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>VoIPService</serviceType>
      <serviceName>IPScale</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>QOSService</serviceType>
      <serviceName>MPLSVPNEFExtraChannel</serviceName>
      <ipVersionValue>
        <ipVersion>
          <ipVersion>IPv4</ipVersion>
          <ipVersion>IPv6</ipVersion>
        </ipVersion>
      </ipVersionValue>
      <poiValue />
      <ipQos />
    </netServices>
    <netServices>
      <serviceType>MPLSService</serviceType>
      <serviceName>MPLSVPNQOS</serviceName>
      <ipVersionValue>
        <ipVersion>
          <ipVersion>IPv4</ipVersion>
          <ipVersion>IPv6</ipVersion>
        </ipVersion>
      </ipVersionValue>
      <poiValue />
      <ipQos />
    </netServices>
    <vectoring>
      <status>No</status>
    </vectoring>
    <scenario>0</scenario>
    <netBandwidth>
      <lineNumber>1</lineNumber>
      <netServiceBandwidth>
        <maxUpStream>
          <lineSpeedUp>1000000</lineSpeedUp>
          <lineSpeedDown>1000000</lineSpeedDown>
        </maxUpStream>
        <maxDownStream>
          <lineSpeedUp>1000000</lineSpeedUp>
          <lineSpeedDown>1000000</lineSpeedDown>
        </maxDownStream>
      </netServiceBandwidth>
      <netServiceMaxLineSpeed>
        <maxUpStream>
          <lineSpeedUp>1000000</lineSpeedUp>
          <lineSpeedDown>1000000</lineSpeedDown>
        </maxUpStream>
        <maxDownStream>
          <lineSpeedUp>1000000</lineSpeedUp>
          <lineSpeedDown>1000000</lineSpeedDown>
        </maxDownStream>
      </netServiceMaxLineSpeed>
    </netBandwidth>
    <netServiceChange>
      <changePort changes="No" />
    </netServiceChange>
    <splitterEnabled>
      <hasSplitter>Yes</hasSplitter>
    </splitterEnabled>
    <technology equipment="H1" />
  </customerNetService>
  <estimatedMaxBandwidth>2000000</estimatedMaxBandwidth>
  <estimatedBandwidth>
    <lineSpeedUp>1000000</lineSpeedUp>
    <lineSpeedDown>1000000</lineSpeedDown>
  </estimatedBandwidth>
  <estimatedMaxBandwidthList>
    <lineNumber>1</lineNumber>
    <estimatedMaxBandwidth>2000000</estimatedMaxBandwidth>
    <estimatedBandwidth>
      <lineSpeedUp>1000000</lineSpeedUp>
      <lineSpeedDown>1000000</lineSpeedDown>
    </estimatedBandwidth>
  </estimatedMaxBandwidthList>
  <estimatedAttenuation>0</estimatedAttenuation>
  <bandwidthCalculationMethod bandwidthExceeded="FIBER" />
  <technology equipment="H1" />
  <fiberEnabled />
  <lid>210723838</lid>
  <gksHouseKey>0A3F50B6706232B8E0440003BA298018</gksHouseKey>
  <pbPossible>false</pbPossible>
  <connectorCablesStatus />
  <inventoryFetchType>VSCACHE_NUUDAY</inventoryFetchType>
  <mawisId />
  <modifyDate />
  <addressIndicator>0A3F50B6706232B8E0440003BA298018</addressIndicator>
  <location />
  <externalInstallationID />
  <owner />
  <ethernetEarliestDeliveryDate>2022-07-14</ethernetEarliestDeliveryDate>
  <rawFiberEarliestDeliveryDate />
  <lockSpeedUse>false</lockSpeedUse>
  <availableCapacity>true</availableCapacity>
  <addressUid>2787866</addressUid>
  <networkCapacityCheck>
    <installationId>1154120</installationId>
    <leadTime>0</leadTime>
    <needIoAppointment>false</needIoAppointment>
    <validCpeFeature>false</validCpeFeature>
    <responseCode>false</responseCode>
    <infrastructurStatus>0</infrastructurStatus>
    <installationCode>0</installationCode>
    <priceCatagory>F</priceCatagory>
    <leadTimeCalcDate>2021-11-22</leadTimeCalcDate>
    <productType>H1</productType>
    <addressUid>2787866</addressUid>
    <capacityCheckObj />
  </networkCapacityCheck>
  <technicianRequired>false</technicianRequired>
  <installationId>1154120</installationId>
  <rftvearliestDeliveryDate />
</customerServices>
</visitationByAddressReply>`

export const bandwidthRequest = `<calcBandwidthByAddressRequest>
<requestInfo>
  <userId>PCICSCON</userId>
  <trackingId>CUPO9752:250906764   14.22.42.140936</trackingId>
  <versionId>1.0</versionId>
  <companyId>Nuuday</companyId>
</requestInfo>
<calcBandwidthByAddress>
  <customVisitationKey>250906764</customVisitationKey>
  <inventoryType>INCA</inventoryType>
  <lid>EF751856</lid>
  <ossLid />
  <alternativeLid />
  <zipCode />
  <locality />
  <streetName />
  <houseNo>16</houseNo>
  <letter />
  <floor />
  <side />
  <appartment />
  <municipalityCode>730</municipalityCode>
  <streetCode>997</streetCode>
  <customerService>
    <netService>
      <serviceType />
      <serviceName>DHCP</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netService>
    <serviceSpeedDown>220000</serviceSpeedDown>
    <serviceSpeedUp>220000</serviceSpeedUp>
    <speedMode>SHARED</speedMode>
    <numberOfSettopCannel />
    <sik>C9951561</sik>
    <prefixLength />
    <category>MAIN</category>
  </customerService>
  <customerService>
    <netService>
      <serviceType />
      <serviceName>TV</serviceName>
      <ipVersionValue />
      <poiValue />
      <ipQos />
    </netService>
    <serviceSpeedDown>147456</serviceSpeedDown>
    <serviceSpeedUp>2304</serviceSpeedUp>
    <speedMode>STATIC</speedMode>
    <numberOfSettopCannel />
    <sik>C9951562</sik>
    <prefixLength />
    <category>APPEND</category>
  </customerService>
  <isdnPstn>FIBER</isdnPstn>
  <listOfGreenBroadbandServices />
  <ispId />
  <technologyChoise />
  <lightVersionIfPossible>true</lightVersionIfPossible>
  <ipVersion />
  <splitter />
  <ginpStatus />
  <customerPromiseDate>2022-08-08</customerPromiseDate>
  <cpeFeatureAction>MUST</cpeFeatureAction>
  <cpeFeatures>
    <cpeFeature>RFTV</cpeFeature>
    <cpeFeature>MultiChannel</cpeFeature>
    <cpeFeature>SingleChannel</cpeFeature>
    <cpeFeature>FiberGPON</cpeFeature>
    <cpeFeature>FiberP2P</cpeFeature>
    <cpeFeature>1Gigabit</cpeFeature>
    <cpeFeature>100Megabit</cpeFeature>
  </cpeFeatures>
  <useCurrentLid />
  <addressOwner>Official</addressOwner>
  <addressIndicator />
  <fiberCpeFeatures />
  <kapID />
  <kapPort />
  <productType>ENIIG</productType>
  <externalKapID>E200248401</externalKapID>
  <owner />
  <preSaleCapacityCheck>true</preSaleCapacityCheck>
  <cplines />
</calcBandwidthByAddress>
</calcBandwidthByAddressRequest>`

export const bandwidthReply = `<calcBandwidthByAddressReply>
<replyInfo>
  <replyTimestamp>2022-07-14 12:22:43</replyTimestamp>
  <statusCode>0</statusCode>
  <severityLevel>0</severityLevel>
  <statusMessage>OK</statusMessage>
  <transactionDuration>645</transactionDuration>
  <serverId>nuu-visitation</serverId>
  <trackingId>CUPO9752:250906764   14.22.42.140936</trackingId>
</replyInfo>
<customerBandwidth>
  <estimatedMaxBandwidth bandwidthExceeded="GREEN" />
  <portChangeRequired changes="No" />
  <ipChangeRequired changes="No" />
  <ipVersion>IPV4</ipVersion>
  <technology equipment="H1" />
  <maxBandwidth>
    <lineSpeedUp>1000000</lineSpeedUp>
    <lineSpeedDown>1000000</lineSpeedDown>
  </maxBandwidth>
  <linespeed>
    <lineSpeedUp>220000</lineSpeedUp>
    <lineSpeedDown>220000</lineSpeedDown>
  </linespeed>
  <speedMode>STATIC</speedMode>
  <remainingLineSpeed>
    <lineSpeed>780000</lineSpeed>
  </remainingLineSpeed>
  <greenBroadbandItems />
  <calcBandwidthId>E200248401</calcBandwidthId>
  <cpeFeatures>
    <cpeFeature>100Megabit</cpeFeature>
    <cpeFeature>FiberGPON</cpeFeature>
    <cpeFeature>SingleChannel</cpeFeature>
  </cpeFeatures>
  <channelTypeChange changes="No" />
  <ginp>
    <status>No</status>
  </ginp>
  <vectoring>
    <status>No</status>
  </vectoring>
  <inventoryFetchType>VSCACHE_NUUDAY_REAL_TIME</inventoryFetchType>
  <mawisId />
  <addressIndicator>E200248401</addressIndicator>
  <externalInstallationID />
  <owner>OPENNET</owner>
  <earliestDeliveryDate>2022-08-05</earliestDeliveryDate>
  <installationId />
  <productType />
  <technicianRequired>true</technicianRequired>
  <availablecapacity>true</availablecapacity>
  <networkCapacityCheck />
  <addressUid />
  <installationCode />
  <installationSeq />
  <kapInfo>
    <kapInfo>
      <message>No KapType Found!!</message>
    </kapInfo>
  </kapInfo>
</customerBandwidth>
</calcBandwidthByAddressReply>`

export const techRequest = `<technicalAllocationParamsWithUniqueKeyRequest>
<requestInfo>
  <userId>NuuOssi</userId>
  <trackingId>SOM-250931682/OSSIID: 0</trackingId>
  <versionId>0.0</versionId>
  <companyId>Nuuday</companyId>
</requestInfo>
<technicalAllocationParamsWithUniqueKey>
  <serviceType />
  <serviceName />
  <calcBandwidthId />
  <purchaseOrder>250931682</purchaseOrder>
  <univpi />
  <univci />
  <uniVLanId />
  <sik />
  <macCalc>
    <ipItems>1</ipItems>
  </macCalc>
  <mtu />
</technicalAllocationParamsWithUniqueKey>
</technicalAllocationParamsWithUniqueKeyRequest>`

export const techReply = `<technicalAllocationParamsWithUniqueKeyReply>
<replyInfo>
  <replyTimestamp>2022-07-15 07:42:31</replyTimestamp>
  <statusCode>0</statusCode>
  <severityLevel>0</severityLevel>
  <statusMessage>OK</statusMessage>
  <transactionDuration>0</transactionDuration>
  <serverId>nuu-visitation</serverId>
  <trackingId>CUPO9752:250931682   09.42.30.344895</trackingId>
</replyInfo>
<customerFacingServicesWithinTheChannel>
  <cfsWithinTheChannel>
    <serviceType>BroadbandService</serviceType>
    <serviceName>DHCP</serviceName>
    <sik>C9953411</sik>
  </cfsWithinTheChannel>
</customerFacingServicesWithinTheChannel>
<channelInfo>
  <ipQoS>tdc:0-0-0</ipQoS>
  <channelSpeedDownStream>60000</channelSpeedDownStream>
  <channelSpeedUpStream>60000</channelSpeedUpStream>
  <multicast>false</multicast>
  <multiService>false</multiService>
  <lineSpeedDownStream>60000</lineSpeedDownStream>
  <lineSpeedUpStream>60000</lineSpeedUpStream>
  <speedMode>Static</speedMode>
</channelInfo>
<openNetChannelInfo>
  <ipQoS />
  <multiService>false</multiService>
  <multicast>false</multicast>
  <channelSpeedDownStream>60000</channelSpeedDownStream>
  <channelSpeedUpStream>60000</channelSpeedUpStream>
  <lineSpeedDownStream>60000</lineSpeedDownStream>
  <lineSpeedUpStream>60000</lineSpeedUpStream>
  <speedMode>Static</speedMode>
  <tariffCode>Fibia BSA</tariffCode>
  <traficOwner>sur689</traficOwner>
  <addressUID>0a3f50b1-a0d4-32b8-e044-0003ba298018</addressUID>
  <installationid>IA01944163</installationid>
  <mac>8</mac>
  <productType />
  <mtu />
  <earliestDeliveryDate>2022-08-05</earliestDeliveryDate>
</openNetChannelInfo>
<accessLineSpeed />
</technicalAllocationParamsWithUniqueKeyReply>`