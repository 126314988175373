import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBody from '../../common/error/ErrorBody';
import { faPlusSquare, faEdit, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Notify from '../Notify';
import Loading from '../../common/loading/Loading';
import axios, { hasAccess } from '../../../api/management_api';

function Cpe() {
    const [tableData, setTableData] = useState();
    const [edit, setEdit] = useState({ id: '' });
    const [error, setError] = useState();
    const [notifyData, setNotifyData] = useState({ title: '' });
    const access = useState(hasAccess())[0];

    //call on load
    useEffect(() => {
        refreshData();
    }, [])

    const refreshData = () => {
        axios.get('/v1/cpe/getAllCpe')
            .then(res => {
                setTableData(res.data);
            }).catch(err => setError(err.message));
    }

    const clickSave = () => {
        setNotifyData({ title: `Want to save ${edit.cpe} ?`, method: performSave })
    }
    const performSave = () => {
        setNotifyData({ title: `trying to save ${edit.cpe} !`, method: '' })
        axios.post('/v1/cpe/createUpdateCpe', { ...edit, qosType: edit.qosType === "" ? null : edit.qosType })
            .then(res => {
                refreshData();
                setNotifyData({ title: `Successfully saved ${edit.cpe} !`, method: '' });
                setEdit({ id: '' });
            }).catch(err => {
                setNotifyData({ title: err.message, method: '' });
                setEdit({ id: '' });
            })
    }

    const deleteClick = () => {
        setNotifyData({ title: `Want to delete ${edit.cpe} ?`, method: performDeleteClick })
    }

    const performDeleteClick = () => {
        setNotifyData({ title: `Trying to delete ${edit.cpe} !`, method: '' });
        axios.delete(`/v1/cpe/deleteCpe/${edit.id}`)
            .then(res => {
                refreshData();
                setNotifyData({ title: `Successfully deleted ${edit.cpe} !`, method: '' });
                setEdit({ id: '' });
            }).catch(err => {
                setNotifyData({ title: err.message, method: '' });
                setEdit({ id: '' });
            })
    }
    const validateField = () => {
        return edit.cpe && edit.cfsPriority && edit.cfsService && edit.vlanIdPriority && edit.uniVlanId;
    }

    return (
        <div className="card">
            <Notify notifyData={notifyData} />
            <h5 className="card-header">Cpe and UniVlan</h5>
            <div className="card-body">
                <div className="div-table" style={{ maxHeight: "65vh", overflowY: "auto" }}>
                    <div style={{ position: 'relative', overflowX: 'auto', textAlign: 'center' }}>
                        {error ? <ErrorBody errorMessage={error} /> :
                            <table className="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        {access ? <th style={{ width: '8%' }}>
                                            <button className="btn btn-success" disabled={edit.id === 0 ? true : false}
                                                onClick={() => setEdit({
                                                    id: 0,
                                                    cpe: '',
                                                    cfsPriority: '',
                                                    ipType: '',
                                                     qosType: '',
                                                    cfsService: '',
                                                    vlanIdPriority: '',
                                                    uniVlanId: '',
                                                    multiService: false
                                                })}>
                                                <FontAwesomeIcon icon={faPlusSquare} /></button></th> : null}
                                        <th>cpe</th>
                                        <th>cfsPriority</th>
                                        <th>ipType</th>
                                        <th>qosType</th>
                                        <th>cfsService</th>
                                        <th>vlanIdPriority</th>
                                        <th>uniVlanId</th>
                                        <th>multiService</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        //add new property
                                        edit.id === 0 ? <tr>
                                            <td><div style={{ display: 'inline-flex' }}>
                                                <button className="btn btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                                    disabled={validateField() ? false : true}
                                                    onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                                <button className="btn btn-danger" onClick={() => setEdit({ id: '' })}>
                                                    <FontAwesomeIcon icon={faTimes} /></button>
                                            </div></td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.cpe}
                                                onChange={(e) => setEdit({ ...edit, cpe: e.target.value })} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.cfsPriority}
                                                onChange={(e) => setEdit({ ...edit, cfsPriority: e.target.value })} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.ipType}
                                                onChange={(e) => setEdit({ ...edit, ipType: e.target.value })} /></td>
                                            <td><select className="form-control form-control-sm" value={edit.qosType}
                                                onChange={(e) => setEdit({ ...edit, qosType: e.target.value })} style={{ width: 'auto' }} >
                                                <option defaultValue value="">NONE</option>
                                                <option value="AFB">AFB</option>
                                                <option value="AFNB">AFNB</option>
                                                <option value="BE">BE</option>
                                                <option value="EF">EF</option>
                                            </select>
                                            </td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.cfsService}
                                                onChange={(e) => setEdit({ ...edit, cfsService: e.target.value })} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.vlanIdPriority}
                                                onChange={(e) => setEdit({ ...edit, vlanIdPriority: e.target.value })} /></td>
                                            <td><input type="text" className="form-control form-control-sm" value={edit.uniVlanId}
                                                onChange={(e) => setEdit({ ...edit, uniVlanId: e.target.value })} /></td>
                                            <td><select className="form-control form-control-sm" value={edit.multiService}
                                                onChange={(e) => setEdit({ ...edit, multiService: e.target.value })} >
                                                <option>false</option><option>true</option></select>
                                            </td>
                                        </tr> : null
                                    }

                                    {// existing table if data present
                                        tableData ? tableData.map((item, index) => <tr key={index}>
                                            {item.id === edit.id ? <>
                                                <td><div style={{ display: 'inline-flex' }}>
                                                    <button className="btn btn-secondary mr-2" data-toggle="modal" data-target="#notifyModal"
                                                        disabled={validateField() ? false : true}
                                                        onClick={() => clickSave()}><FontAwesomeIcon icon={faSave} /></button>
                                                    <button className="btn btn-danger" onClick={() => setEdit({ id: '' })}>
                                                        <FontAwesomeIcon icon={faTimes} /></button>
                                                </div></td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.cpe}
                                                    onChange={(e) => setEdit({ ...edit, cpe: e.target.value })} /></td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.cfsPriority}
                                                    onChange={(e) => setEdit({ ...edit, cfsPriority: e.target.value })} /></td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.ipType}
                                                    onChange={(e) => setEdit({ ...edit, ipType: e.target.value })} /></td>
                                                <td><select className="form-control form-control-sm" value={edit.qosType}
                                                    onChange={(e) => setEdit({ ...edit, qosType: e.target.value })} style={{ width: 'auto' }}>
                                                    <option defaultValue value="">NONE</option>
                                                    <option value="AFB">AFB</option>
                                                    <option value="AFNB">AFNB</option>
                                                    <option value="BE">BE</option>
                                                    <option value="EF">EF</option>
                                                </select>
                                                </td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.cfsService}
                                                    onChange={(e) => setEdit({ ...edit, cfsService: e.target.value })} /></td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.vlanIdPriority}
                                                    onChange={(e) => setEdit({ ...edit, vlanIdPriority: e.target.value })} /></td>
                                                <td><input type="text" className="form-control form-control-sm" value={edit.uniVlanId}
                                                    onChange={(e) => setEdit({ ...edit, uniVlanId: e.target.value })} /></td>
                                                <td><select className="form-control form-control-sm" value={edit.multiService}
                                                    onChange={(e) => setEdit({ ...edit, multiService: e.target.value })} >
                                                    <option>false</option><option>true</option></select>
                                                </td>
                                                <td style={{ textAlign: 'center' }}><button className="btn btn-outline-danger"
                                                    data-toggle="modal" data-target="#notifyModal"
                                                    onClick={() => deleteClick()}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                            </> :
                                                <>
                                                    {access ? <td> <button className="btn btn-info"
                                                        onClick={() => setEdit(item)}>
                                                        <FontAwesomeIcon icon={faEdit} /></button></td> : null}
                                                    <td>{item.cpe}</td>
                                                    <td>{item.cfsPriority}</td>
                                                    <td>{item.ipType}</td>
                                                    <td>{item.qosType}</td>
                                                    <td>{item.cfsService}</td>
                                                    <td>{item.vlanIdPriority}</td>
                                                    <td>{item.uniVlanId}</td>
                                                    <td>{item.multiService.toString()}</td>
                                                </>
                                            }

                                        </tr>) : <tr style={{ background: '#fff' }}><td colSpan="10"><Loading /></td></tr>}
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Cpe);