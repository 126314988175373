import React from 'react';

function Notify({ notifyData }) {
    return (
        <div className="modal fade" id="notifyModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLabel">{notifyData.title}</h6>
                        <span style={{ cursor: "pointer" }} className="close" data-dismiss="modal" aria-label="Close">
                            x
              </span>
                    </div>
                    {notifyData.method && <div className="modal-body">
                        <div style={{ display: 'inline-flex', float: 'right' }}>
                            <button className="btn btn-secondary mr-2" onClick={() => notifyData.method()}>Yes</button>
                            <button className="btn btn-danger" data-dismiss="modal">No</button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>

    );
}

export default React.memo(Notify);