export const vendors = [
    { name: "TDC", value: 1, infrastructureType: "FIBER" },
    { name: "ENIIG", value: 2, infrastructureType: "FIBER" },
    { name: "FIBIA", value: 3, infrastructureType: "FIBER" },
    { name: "EWII", value: 4, infrastructureType: "FIBER" },
    { name: "SYDENERGI", value: 5, infrastructureType: "FIBER" },
    { name: "NORDENERGI", value: 6, infrastructureType: "FIBER" },
    { name: "THYMORS", value: 7, infrastructureType: "FIBER" },
    { name: "ENERGIFYN", value: 8, infrastructureType: "FIBER" },
    { name: "COAX", value: 9, infrastructureType: "COAX" },
    { name: "COAX", value: 1, infrastructureType: "COAX" }
];

export const getVendor = (data) => vendors.find(v => v.value === data.vendorID && v.infrastructureType === data.infrastructureType)?.name;

export const decodeStaging = (jsonData, vendor) => {
    return {
        "resource": getResource(jsonData, vendor),
        "installationId": getInstallationId(jsonData, vendor),
        "status": getStatus(jsonData, vendor),
        "available": getAvailable(jsonData, vendor)
    }
}

const getResource = (jsonData, vendor) => {
    if (vendor === "TDC" || vendor === "EWII") {
        let res = [];
        if (jsonData.PTP_LOCATION) {
            res.push(jsonData.PTP_LOCATION);
        }
        if (jsonData.GPON_LOCATION) {
            res.push(jsonData.GPON_LOCATION);
        }
        if (jsonData.XGPON_LOCATION) {
            res.push(jsonData.XGPON_LOCATION);
        }
        return res.join(",");
    }

    if (vendor === "ENIIG" || vendor === "SYDENERGI" || vendor === "NORDENERGI" || vendor === "THYMORS") {
        return jsonData?.PRODUCTCODE;
    }
    if (vendor === "COAX") {
        return jsonData?.DOCSISTYPE;
    }
    return "";
}

const getInstallationId = (jsonData, vendor) => {
    if (vendor === "TDC" || vendor === "EWII") {
        return jsonData?.EXTERNAL_INSTALLATION_ID;
    }

    if (vendor === "ENIIG" || vendor === "SYDENERGI" || vendor === "NORDENERGI" || vendor === "THYMORS") {
        return jsonData?.INSTALLATIONID;
    }

    if (vendor === "FIBIA" || vendor === "ENERGIFYN") {
        return jsonData?.GROGRAPHICSITEID;
    }

    if (vendor === "COAX") {
        return jsonData?.INSTALLATIONSEQUENCE;
    }
    return "";
}

const getStatus = (jsonData, vendor) => {
    if (vendor === "TDC" || vendor === "EWII") {
        return jsonData?.INSTALLATION_CODE;
    }

    if (vendor === "ENIIG" || vendor === "SYDENERGI" || vendor === "NORDENERGI" || vendor === "THYMORS") {
        return jsonData?.INFRASTRUCTURESTATUS;
    }

    if (vendor === "FIBIA" || vendor === "ENERGIFYN") {
        return jsonData?.FIBERSTATUS;
    }

    if (vendor === "COAX") {
        return jsonData?.INSTALLATIONSTATUS;
    }
    return "";
}

const getAvailable = (jsonData, vendor) => {
    if (vendor === "TDC" || vendor === "EWII") {
        return jsonData?.STATUS;
    }

    if (vendor === "ENIIG" || vendor === "SYDENERGI" || vendor === "NORDENERGI" || vendor === "THYMORS") {
        return jsonData?.AVAILABLE;
    }

    if (vendor === "FIBIA" || vendor === "ENERGIFYN") {
        return jsonData?.STATUS;
    }

    if (vendor === "COAX") {
        return jsonData?.DELFLAG;
    }
    return "";
}


