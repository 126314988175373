import React, { useState } from 'react';
import axios from '../../../api/management_api';
import ReactDataTable from './ReactDataTable';
import ErrorBody from '../../common/error/ErrorBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Staging() {

    const [error, setError] = useState();
    const [info, setInfo] = useState([]);
    const [load, setLoad] = useState(false);
    const [activeTab, setActiveTab] = useState("search_address");
    const [address, setAddress] = useState({
        houseNumber: "",
        houseLetter: "",
        side: "",
        floor: "",
        streetName: "",
        postCode: "",
        vendor: "EWII",
        madId: "",
        namId: "",
        vendorId: "",
        searchByAddress: true,
        madIdEnabled: false,
        namIdEnabled: false,
        vendorIdEnabled: false
    });

    const [id, setId] = useState("");
    const [idType, setIdType] = useState("");
    const resetAddress = () => {
        setAddress({
            houseNumber: "",
            houseLetter: "",
            side: "",
            floor: "",
            streetName: "",
            postCode: "",
            vendor: "EWII",
            madId: "",
            namId: "",
            vendorId: "",
            searchByAddress: true,
            madIdEnabled: false,
            namIdEnabled: false,
            vendorIdEnabled: false
        });
        setId('');
        setIdType('');
    }

    const handleAddress = (e) => {
        let name = e.target.name;
        let value = e.target.value.trim();
        setAddress(pre => ({ ...pre, [name]: value }));
    }

    const findStaging = (e) => {
        e.preventDefault();
        setLoad(true);
        setError("");
        setInfo([]);
        axios.post("/v2/transaction/getStagingInventory", address)
            .then(res => {
                if (res.data.length === 0) {
                    setError("Not Data Found");
                } else {
                    setInfo(res.data);
                }
                setLoad(false);
            }).catch((err) => {
                if (err.message.includes("504")) {
                    setError("Request timeout. Please retry");
                } else if (err.message.includes("500")) {
                    setError("Internal error");
                } else if (err.message.includes("401")) {
                    setError("Token expire. Please refresh the page.");
                } else {
                    setError("Bad Request");
                }
                setLoad(false);
            });
    }

    const handleTab = (tabValue) => {
        setActiveTab(tabValue);
        setError("");
        setInfo([]);
        setAddress(pre => ({ ...pre, searchByAddress: tabValue === 'search_address' }));
    }

    const handleRadioSelection = (value) => {
        setIdType(value);
        const keyName = `${value}Enabled`;
        if (id) {
            setAddress(pre => ({ ...pre, [value]: id, [keyName]: true }));
        } else {
            setAddress(pre => ({ ...pre, [keyName]: true }));
        }

    }

    const handleId = (value) => {
        //const keyName = idType;
        setId(value);
        setAddress(pre => ({ ...pre, [idType]: value }));
    }

    const handleVendorSelection = (value) => setAddress(pre => ({ ...pre, vendor: value }));

    return (
        <div>
            <div className="card">
                <h5 className="card-header">Staging Inventory</h5>
                <div className='col-sm-4' style={{ margin: '20px 0' }}>
                    <label className=''>Vendor</label>
                    <select id="address-type" className="form-control form-control-sm"
                        value={address.vendor} onChange={(e) => handleVendorSelection(e.target.value)}>
                        <option value="EWII" selected>EWII</option>
                        <option value="Fibia">Fibia</option>
                        <option value="TDC Coax">TDC Coax</option>
                        <option value="TDC Fiber">TDC Fiber</option>
                        <option value="ENIIG">Opennet</option>
                    </select>
                </div>
                <div className="col-lg-12 col-md-12" style={{ padding: '0 5px' }}>
                    <div className="card" style={{ minHeight: 200 }}>
                        <div className="card-content">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link mr-1 active" id="search-tab-address" style={{ minWidth: "10rem" }} data-toggle="tab" href="#search_address" role="tab" aria-controls="search" aria-selected="true"
                                        onClick={() => handleTab("search_address")}>
                                        Search with address
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link mr-1" id="search-tab-id" data-toggle="tab" href="#search_id" style={{ minWidth: "12rem" }} role="tab" aria-controls="search" aria-selected="true"
                                        onClick={() => handleTab("search_id")}>
                                        Search By id
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="search_address" role="tabpanel" aria-labelledby="search-tab">
                                    {activeTab === "search_address" &&
                                        <div className="div-table my-2">
                                            <div className="form-row">
                                                <div className="div-table col-sm-12 col-md-12 mx-auto" style={{ background: "#f0f1f375" }}>
                                                    <div className="form-row">
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="madId">Street Name*</label>
                                                            <input type="text" className="form-control form-control-sm" name="streetName"
                                                                value={address.streetName} onChange={handleAddress} />
                                                        </div>
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="installationId">House no*</label>
                                                            <input type="text" className="form-control form-control-sm" name="houseNumber"
                                                                value={address.houseNumber} onChange={handleAddress} />
                                                        </div>
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="installationId">House letter</label>
                                                            <input type="text" className="form-control form-control-sm" name="houseLetter"
                                                                value={address.houseLetter} onChange={handleAddress} />
                                                        </div>
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="installationId">Floor</label>
                                                            <input type="text" className="form-control form-control-sm" name="floor"
                                                                value={address.floor} onChange={handleAddress} />
                                                        </div>
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="installationId">Side</label>
                                                            <input type="text" className="form-control form-control-sm" name="side"
                                                                value={address.side} onChange={handleAddress} />
                                                        </div>
                                                        <div className="form-group col-sm-2 col-md-2">
                                                            <label htmlFor="installationId">Post Code*</label>
                                                            <input type="text" className="form-control form-control-sm" name="postCode"
                                                                value={address.postCode} onChange={handleAddress} maxLength="4" />
                                                        </div>

                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-sm text-center">
                                                            <button type="submit" id="search-btn" className="btn btn-info"
                                                                disabled={!address.streetName || !address.postCode || !address.houseNumber}
                                                                onClick={(e) => findStaging(e)}>Search</button>
                                                            <button id="search-btn" className="btn btn-danger ml-2"
                                                                onClick={() => resetAddress()}>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="tab-pane fade" id="search_id" role="tabpanel" aria-labelledby="search-tab">
                                    {activeTab === "search_id" &&
                                        <div className="mt-3 text-sm search-id-content">
                                            <div className="form-row gy-2">
                                                <div className=" col-md-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadioInline1" name="customRadioInline1"
                                                                    className="custom-control-input"
                                                                    checked={idType === "madId"}
                                                                    onChange={() => handleRadioSelection("madId")} />
                                                                <label className="custom-control-label" htmlFor="customRadioInline1">Mad Id</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadioInline2" name="customRadioInline1"
                                                                    className="custom-control-input"
                                                                    checked={idType === "namId"}
                                                                    onChange={() => handleRadioSelection("namId")} />
                                                                <label className="custom-control-label" htmlFor="customRadioInline2">
                                                                    Nam Id</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadioInline3" name="customRadioInline1"
                                                                    className="custom-control-input"
                                                                    checked={idType === "vendorId"}
                                                                    onChange={() => handleRadioSelection("vendorId")} />
                                                                <label className="custom-control-label" htmlFor="customRadioInline3">Vendor Id</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-content">
                                                    <textarea type="text" className="form-control form-control-sm" rows="6" id="ids"
                                                        placeholder={"E.g.\n102DE662-C4F3-45AD-8A56-D23AF6C4A4C8\nAC7B899B-F7A9-42C9-8E37-2C584746A809\nor\n102DE662-C4F3-45AD-8A56-D23AF6C4A4C8, AC7B899B-F7A9-42C9-8E37-2C584746A809"}
                                                        value={id}
                                                        onChange={(e) => handleId(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm text-center">
                                                    <button type="submit" className="btn btn-info"
                                                        disabled={!id || !idType}
                                                        onClick={(e) => findStaging(e)}>Search</button>
                                                    <button className="btn btn-danger ml-2"
                                                        onClick={() => resetAddress()}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-table mb-3">
                    {error ? <ErrorBody errorMessage={error} /> :
                        info.length > 0 ? <ReactDataTable rowData={info} /> :
                            <div className="text-center p-4 text-wrap">
                                <FontAwesomeIcon className="mb-3" icon={faSearch} size="5x" color="rgb(87, 179, 193)"
                                    spin={load ? true : false} />
                                <h2 style={{ color: "rgb(73, 80, 87)" }}>
                                    {load ? "Loading" : "Search for Staging"}</h2>
                            </div>}
                </div>
            </div>
        </div>
    );
}

export default Staging;