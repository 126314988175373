import React, { useEffect, useState } from 'react';

function SigmaCFS({cfsData}){
    const [cfsContainer,setCfsContainer]=useState();
    useEffect(()=>{
        setCfsContainer(cfsData.filter(el=>el['serviceQualification']).map(data=>{
            return {name:data.name,parent:data.parentName}
        }));
    },[cfsData])

    return(
        <table className="table table-striped table-sm table-bordered">
        <thead>
            <tr>
                <th>Service</th>
                <th>Parent Service</th>
                <th>Technology</th>
            </tr>
        </thead>
        <tbody>
        {cfsContainer?cfsContainer.map((data,index)=>{
           return <tr key={index}>
                    <td>{data.name}</td>
                    <td>{data.parent}</td>
                    <td>
                     <div className="row mx-1">
                        {cfsData.map(cfs=>{
                            if(cfs.name===data.name){
                                return cfs.serviceQualification.map((tech,index)=>{
                                return <div key={index} className="col-md-3 sigma-cell">{tech.description}</div>
                                }).sort((a,b)=>a.props.children<b.props.children?-1:1)
                            }else return null
                        })}
                     </div>
                     </td>
                </tr>
        }):
        <tr>
            <td colSpan='3'>No data found !</td>
        </tr>
        }
        </tbody>
        </table>
    );

}

export default React.memo(SigmaCFS);