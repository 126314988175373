import React, { useState, useEffect } from 'react';
import Loading from '../../common/loading/Loading';
import axios from '../../../api/management_api';
import ErrorBody from '../../common/error/ErrorBody';
import Notify from '../Notify';
import { isEmptyObj } from '../../common/util';
import CommonTable from './CommonTable';

function Cache() {
    const [result, setResult] = useState(null);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState("");
    const [cacheKeys, setCacheKeys] = useState([]);
    const [notifyData, setNotifyData] = useState({ title: '' });
    const [tableData, setTableData] = useState({ title: "", data: null });

    useEffect(() => {
        setLoad(true);
        refreshData({ title: "", data: null });
    }, [])

    const refreshData = () => {
        setError("");
        setResult(null);
        setTableData({ title: "", data: null });
        axios.get('/v1/cache/viewCache')
            .then(res => {
                if (res.data && !isEmptyObj(res.data)) {
                    setResult(res.data);
                    setCacheKeys(Object.keys(res.data).filter(k => !isEmptyObj(res.data[k])));
                } else {
                    setError("No Cache found");
                }

                setLoad(false);
            }).catch(err => setError(err.message));
    }

    const deleteClick = () => {
        setNotifyData({ title: "Want to delete cache ?", method: performDeleteClick })
    }

    const performDeleteClick = () => {
        axios.post('/v1/cache/clearCache')
            .then(res => {
                refreshData();
                setNotifyData({ title: res.data.statusMessage, method: '' })
            }).catch(err => setError(err.message));
    }

    const createTableData = (key) => {

        if (key === 'nuuOssiToken') {
            setTableData({
                title: key,
                data: [{ key: "Token", value: result.nuuOssiToken['SimpleKey []'].access_token }]
            });
        }
        if (key === 'permittedInstallations') {
            setTableData({
                title: key,
                data: result.permittedInstallations['SimpleKey []'].map(id => ({ "key": "installationId", "value": id.installationId }))
            });
        }

        if (key === 'macAddress') {
            console.log(Object.entries(result.macAddress).map(([key, value]) => ({ key: key, value: JSON.stringify(value) })));
            setTableData({
                title: key,
                data: Object.entries(result.macAddress).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }))
            });
        }

        if (key === 'vendorSpeed') {
            setTableData({
                title: key,
                data: Object.entries(result.vendorSpeed).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }))
            });
        }

        if (key === 'commercialSpeed') {
            let com = result.commercialSpeed['SimpleKey []'];
            let vendor = [...new Set(com.map(c => c.vendor))];
            let temp = vendor.map(v => ({ key: v, value: com.filter(c => c.vendor === v) }));
            setTableData({
                title: key,
                data: temp.map(t => ({ key: t.key, value: JSON.stringify(t.value) }))
            });
        }
    }

    const deleteSpecificCache = () => {
        setNotifyData({ title: `Want to delete ${tableData.title} cache ?`, method: performDeleteSpecificCache })
    }

    const performDeleteSpecificCache = () => {
        axios.delete(`/v1/cache/clearSpecificCache?cacheName=${tableData.title}`)
            .then(res => {
                refreshData();
                setNotifyData({ title: res.data.statusMessage, method: '' })
            }).catch(err => setError(err.message));
    }


    return (
        <div className="card">
            <h4 className="card-header">Cache Management
            <button style={{ float: 'right' }} className='btn btn-outline-secondary' type="button" data-toggle="modal" data-target="#notifyModal"
                    onClick={() => deleteClick()}>Delete cache</button>
            </h4>

            <Notify notifyData={notifyData} />
            <div className="card-body">

                {load ? <Loading /> :
                    error ? <ErrorBody errorMessage={error} /> :
                        <div>
                            {cacheKeys.length > 0 ? cacheKeys.map((k, i) => <button key={i} className={tableData.title === k ? "btn btn-secondary m-1" : "btn btn-info m-1"}
                                onClick={() => createTableData(k)}>{k}</button>)
                                : <h6 style={{ textAlign: "center" }}>Empty cache</h6>}
                        </div>}
                {result && <div className="mt-4" style={{ maxHeight: '80vh', overflowY: "scroll" }}>
                    {tableData?.title === 'nuuOssiToken' && <CommonTable title={tableData.title} tableData={tableData.data} deleteCache={deleteSpecificCache} />}
                    {tableData?.title === 'permittedInstallations' && <CommonTable title={tableData.title} tableData={tableData.data} deleteCache={deleteSpecificCache} />}
                    {tableData?.title === 'macAddress' && <CommonTable title={tableData.title} tableData={tableData.data} deleteCache={deleteSpecificCache} />}
                    {tableData?.title === 'vendorSpeed' && <CommonTable title={tableData.title} tableData={tableData.data} deleteCache={deleteSpecificCache} />}
                    {tableData?.title === 'commercialSpeed' && <CommonTable title={tableData.title} tableData={tableData.data} deleteCache={deleteSpecificCache} />}
                </div>}

            </div>
        </div>
    );
}

export default Cache;