import React, { useState, useEffect } from "react";
import Loading from "../../common/loading/Loading";
import ErrorBody from "../../common/error/ErrorBody";
import SigmaMacSpeed from "./SigmaMacSpeed";
import SigmaCFS from "./SigmaCFS";
import Commercial from "./Commercial";
import axios from "../../../api/management_api";

function Sigma() {
  const [macSpeed, setMacSpeed] = useState();
  const [cfs, setCfs] = useState();
  const [commercial, setCommercial] = useState();
  const [sel, setSel] = useState("MAC");
  const [error, setError] = useState();

  useEffect(() => {
    axios
      .get("/v1/catalog/getSigmaCatalog?resourceType=MAC")
      .then((res) => {
        let result = res.data.product.find((item) => item.name === "L2-ACCESS");
        setMacSpeed(result.serviceQualificationMac);
      })
      .catch((err) => setError(err.message));
  }, []);

  const changeSigma = (value) => {
    setMacSpeed();
    setCommercial();
    setCfs();
    setError();
    setSel(value);
    axios
      .get(`/v1/catalog/getSigmaCatalog?resourceType=${value}`)
      .then((res) => {
        let result = res.data.product.find((item) => item.name === "L2-ACCESS");
        if (value === "MAC") {
          setMacSpeed(result.serviceQualificationMac);
        } else if (value === "SPEED") {
          setMacSpeed(result.serviceQualificationSpeed);
        } else if (value === "CFS") {
          setCfs(res.data.product);
        } else if (value === "COMMERCIAL_SPEED") {
          setCommercial(result.serviceQualificationCommercialSpeed);
        }
      })
      .catch((err) => setError(err.message));
  };

  return (
    <div className="card">
      <h5 className="card-header">Hansen service catalog</h5>
      <div className="card-body">
        <h5 className="card-title">
          <button
            className={
              sel === "MAC"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeSigma("MAC")}
          >
            MAC
          </button>
          <button
            className={
              sel === "SPEED"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeSigma("SPEED")}
          >
            SPEED
          </button>
          <button
            className={
              sel === "CFS"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeSigma("CFS")}
          >
            CFS
          </button>
          <button
            className={
              sel === "COMMERCIAL_SPEED"
                ? "btn btn-secondary m-1 px-5 "
                : "btn btn-info m-1 px-5 "
            }
            type="button"
            onClick={() => changeSigma("COMMERCIAL_SPEED")}
          >
            COMMERCIAL
          </button>
        </h5>

        <div style={{ border: "1px solid rgb(204 213 218)", borderRadius: "3px" }}>
          {error && <ErrorBody errorMessage={error} />}
          {macSpeed && <SigmaMacSpeed macSpeed={macSpeed} sel={sel} />}
          {cfs && <SigmaCFS cfsData={cfs} />}
          {commercial && <Commercial comData={commercial} />}
          {!(error || macSpeed || cfs || commercial) && <Loading />}
        </div>
      </div>
    </div>
  );
}

export default Sigma;
