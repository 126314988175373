import React from "react";
import SuccessImg from "../../../asset/image/success.png";

function Success({ message }) {
  return (
    <div className="loading-class">
      <div className="m-3">
        <img
          style={{ width: "30%" }}
          src={SuccessImg}
          alt="success"
        />
      </div>
      <div>
        <h1>{message}</h1>
      </div>
    </div>
  );
}

export default React.memo(Success);
