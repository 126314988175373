import React from 'react';
import ReactJson from 'react-json-view';
import XmlBeautify from 'xml-beautify';

function Execution({ exec }) {

    const xmlFormatter = (data) => new XmlBeautify().beautify(data, { indent: "  ", useSelfClosingElement: true });

    return (
        <table className="table table-striped table-bordered ">
            <thead>
                <tr>
                    <th scope="col">Reply</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {(exec && exec.resType === 'application/xml') && <pre style={{ fontSize: '1rem' }}>{xmlFormatter(exec.reply)}</pre>}
                        {(exec && exec.resType === 'application/json') && <ReactJson src={exec.reply} indentWidth={6}
                            theme="monokai"
                            enableClipboard={e => navigator.clipboard.writeText(JSON.stringify(e.src))}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            displayArrayKey={false}
                            name={false} />}
                    </td>
                </tr>

            </tbody>
            <thead>
                <tr>
                    <th scope="col">Request</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {(exec && exec.reqType === 'application/xml') && <pre style={{ fontSize: '1rem' }}>{xmlFormatter(exec.req)}</pre>}
                        {(exec && exec.reqType === 'application/json') && <ReactJson src={JSON.parse(exec.req)}
                            theme="monokai" indentWidth={6}
                            enableClipboard={e => navigator.clipboard.writeText(JSON.stringify(e.src))}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            displayArrayKey={false}
                            name={false} />}
                    </td>
                </tr>
            </tbody>

        </table>
    );
}

export default React.memo(Execution);