import React, { useState, useEffect } from 'react';
import ErrorBody from '../../common/error/ErrorBody';
import Loading from '../../common/loading/Loading';
import axios from '../../../api/management_api';

function EnvInfo() {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState();
  const [load, setLoad] = useState(true);

  //call on load
  useEffect(() => {
    refreshData();
  }, [])

  const refreshData = () => {
    setLoad(true);
    axios.get('/v1/system/infolist')
      .then(res => {
        if (res.data?.length === 0) {
          setError("No data found");
        } else {
          setTableData(res.data);
        }
        setLoad(false);
      }).catch(err => {
        setLoad(false);
        setError(err.message);
      });
  }

  return (
    <div className="card">
      <h5 className="card-header">Environment Info</h5>
      <div className="card-body">
        <div className="div-table" style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <div style={{ position: 'relative', overflowX: 'auto', textAlign: 'center' }}>
            {error && <ErrorBody errorMessage={error} />}
            {load && <Loading />}
            {tableData?.length > 0 &&
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  {tableData.map((t, i) => <tr key={i}>
                    <td>{t.variableName}</td>
                    <td>{t.variableValue}</td>
                  </tr>)}
                </tbody>
              </table>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EnvInfo);