import React from 'react';
import { version } from '../../data/constant';
import MONITOR from '../../asset/image/monitor.PNG';
import MANAGEMENT from '../../asset/image/management.PNG';
import TEST from '../../asset/image/test.PNG';
import HISTORY from '../../asset/image/history.PNG';
import MAD from '../../asset/image/mad.PNG';
import HISTORY_RESULT from '../../asset/image/history_result.PNG';
import DETAIL_REQUEST from '../../asset/image/detail_request.PNG';
import DETAIL_RESPONSE from '../../asset/image/detail_response.PNG';
import AI from '../../asset/image/ai.PNG';
import AI_RESULT from '../../asset/image/ai_result.PNG';
import EXECUTE from '../../asset/image/execute.PNG';
import { subscriberReply, subscriberRequest, addressRequest, addressReply, bandwidthReply, bandwidthRequest, techReply, techRequest } from './example';
import XmlBeautify from 'xml-beautify';


export default function PortalInfo() {
  const xmlFormatter = (data) => new XmlBeautify().beautify(data, { indent: "  ", useSelfClosingElement: true });
  return (
    <div className="portal">
      <h1> nuu-visitation-service-admin<i style={{ fontSize: 'large' }}>{version}</i></h1>
      <h5>The idea to create the GUI tool is to have a quick overview of the infrastructure data foundation for each address in Denmark. </h5>
      <h6>Nuuday as service provide gets daily an address whitelist file from Open Net, Fibia and TDC.</h6>
      <ul>
        <li>Open Net address file with fiber infrastructure from Syd Energy, Eniig, Nord Energy and Thy Mors</li>
        <li>Fibia address file with fiber infrastructure from Fibia (Syd Sjælland) and Fyn</li>
        <li>TDC address file with fiber infrastructure from Nord Sjælland and EWII (Kolding area)</li>
        <li>TDC address file with COAX infrastructure in Denmark owned by TDC or private</li>
      </ul>
      <h5 className="my-3">The dashboard is divided into area specific views</h5>
      <div className="row">
        <div className="col col-md-2">
          <img src={MONITOR} className="img-fluid" alt="monitor" />
        </div>
        <div className="col col-md-4">
          <p>Monitor is allowed for all users with INTERNAL user role</p>
          <ul>
            <li>Catalog is directory master data that has mapping rules between resource and services</li>
            <li>History is where it is possible to see all Nuuday application which calls one or more service of Nuuday Visitation</li>
            <li>Access Inventory is where it is possible to see infrastructure data foundation which are loaded inti Nuuday Visitation database</li>
          </ul>
        </div>
        <div className="col col-md-2">
          <img src={MANAGEMENT} className="img-fluid" alt="monitor" />
        </div>
        <div className="col col-md-4">
          <p>Management is only for users with OPERATIONAL user role</p>
          <ul>
            <li>QOS: Quality of service is part of the calculation rules</li>
            <li>CPE: customer-premises equipment which is include by CFS and VLAN prioritizing </li>
            <li>Property: view or change difference properties values used by the Nuuday Visitation application</li>
            <li>Env: Overview of the application environment profiles</li>
            <li>Resource: Maintain the catalog data </li>
            <li>Cache: maintain the internal cache values</li>
          </ul>
        </div>
        <div className="col col-md-2">
          <p>Test service execution is allowed for all users with INTERNAL user role</p>
          <ul>
            <li>Service is where it is possible to retest or execute the Nuuday Visitation service API </li>
          </ul>
        </div>
        <div className="col col-md-2">
          <img src={TEST} className="img-fluid" alt="monitor" />
        </div>
        <div className="col col-md-8 p-1">
          <img src={EXECUTE} className="img-fluid" alt="monitor" />
        </div>


      </div>
      <u><h5 className="my-3"> Monitor History: </h5></u>
      <h6>From History search page is it possible to search in the Nuuday Visitation history log of all the call that is send to Nuuday Visitation by Columbus, eOrder, PDSM (Albert, eWeb) in a specific period. History log keeps data for a month back. </h6>
      <img src={HISTORY} className="img-fluid mt-2" alt="monitor" />
      <table className="table table-small table-striped table-bordered my-3">
        <thead>
          <tr>
            <th>Search attribut name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Request from date</td>
            <td>The date from where calling request is received into Nuuday Visitation</td>
          </tr>
          <tr>
            <td>Request to date</td>
            <td>The date from where calling response is send back to client</td>
          </tr>
          <tr>
            <td>Search by request</td>
            <td>Search with string which should be part of request</td>
          </tr>
          <tr>
            <td>Search by response</td>
            <td>Search with string which should be part of response</td>
          </tr>
          <tr>
            <td>Primary identifier</td>
            <td>Search by primary identifier which is service request specific</td>
          </tr>
          <tr>
            <td>Service Type</td>
            <td>Search by service type, select from drop down list</td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>Search by service type duration time in MS with greater or less condition</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>Search by network provider name, select from drop down list</td>
          </tr>
          <tr>
            <td>Order number</td>
            <td>Search by order number id</td>
          </tr>
          <tr>
            <td>Request id</td>
            <td>search by history row id</td>
          </tr>
          <tr>
            <td>Adresse id</td>
            <td>Search by specific address id</td>
          </tr>
          <tr>
            <td>Records</td>
            <td>Select number of rows in the search page, select from drop down list</td>
          </tr>
        </tbody>
      </table>
      <div className="row mb-3">
        <div className="col col-md-2 py-3">
          <h6>
            Click on the button “Hide” then it is possible to get the address id by searching by full address. Fill out address get click the “Get Madid” and the address id attribute with specific address id.
          </h6>
        </div>
        <div className="col col-md-10">
          <img src={MAD} className="img-fluid" alt="monitor" />
        </div>
      </div>
      <h5>To get the result of your search criteria click the “Search” button and “Reset” will remove your search criteria values.</h5>
      <img src={HISTORY_RESULT} className="img-fluid my-3" alt="monitor" />
      <h5 className="mt-4">To see the request and response of a specific service call click “view” </h5>
      <div className="row">
        <div className="col col-md-6">
          <img src={DETAIL_RESPONSE} className="img-fluid" alt="monitor" />
        </div>
        <div className="col col-md-6">
          <img src={DETAIL_REQUEST} className="img-fluid" alt="monitor" />
        </div>
      </div>
      <u><h5 className="my-4"> Order capture & fulfillment </h5></u>
      <h6>PreQualification:</h6><hr />
      <p>
        When an order creation must be made either from eOrder or Columbus the first Nuuday Visitation service that will be called is Visitation Subscriber, which gives information which fiber installation lines is available on the address. Each installation line is ordered with the lowest installation code as the first element in the info result list.
      </p>
      <p>
        The values from {"<networkCapacityCheck>"} info block is data from the net work owner data load.
      </p>
      <p>
        Columbus selects always automatically the first element from the Visitation Subscriber response, if there is more than one installation line and eOrder or Columbus user want to use another installation line, then user need to change the line as part of the order creation.
      </p>
      <div className="row">
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(subscriberReply)}</pre>
        </div>
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(subscriberRequest)}</pre>
        </div>
      </div>
      <h6>Product offer:</h6>
      <hr />
      <p>After the pre-qualification call is made, then VisitationByAddress is called to get the list of Customer Facing Services and the maximum line speed that can be offered on the installation line that is selected in the order start.</p>
      <div className="row">
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(addressReply)}</pre>
        </div>
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(addressRequest)}</pre>
        </div>
      </div>
      <h6>Bandwidth calculation: </h6>
      <hr />
      <p>
        Each time when an order product is selected as part of the installation line, then eOrder or Columbus calls CalcBandwidthByAddress service where product selection gets validated if it is possible and a real time call to the network provider, see “Real Time validation” section for more.
      </p>
      <p>
        Some time the real time call time exceed and then load data from whitelist will be used, information from the {"<networkCapacityCheck>"} block will be used.
      </p>
      <p>
        To identify real time call is made or not, this can be detected or identified by looking / search for the value “VSCACHE_NUUDAY_REAL_TIME” means that the real time call is made and if it is not made, then only this will be seen “VSCACHE_NUUDAY” in the response.
      </p>
      <div className="row">
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(bandwidthReply)}</pre>
        </div>
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(bandwidthRequest)}</pre>
        </div>
      </div>
      <h6>Real Time validation:</h6>
      <hr />
      <p>Real time validation is a call to network service provide to validate the following information</p>
      <ul>
        <li>
          Earliest Delivery Date - date
        </li>
        <li>
          Technician Required – true / false
        </li>
        <li>
          Available capacity – true / false
        </li>
      </ul>
      <p>The real time information is in the following Nuuday Visitation service </p>
      <ul>
        <li>
          Visitation Subscriber
        </li>
        <li>
          Visitation By Address
        </li>
        <li>
          CalcBandwidth By Address
        </li>
      </ul>
      <p>The real time call is done for the following action</p>
      <ul>
        <li>
          Order action 200 else eOrder / Columbus in the request add {"<PreSaleCapacityCheck>"} tag to be false, no real time to be made.
        </li>
        <li>
          When Columbus has called CalcBandwidth By Address with {"<CpeFeatureAction>"} “MUST” then the final handshake that confirm that the order is valid and can be send down to OSSI / SOM fulfillment flow. CalcBandwidth By Address will then generate the TechParam response and together with the information value of Earliest Delivery Date, Technician Required and Available capacity which then will be reused if the order got changed later.
        </li>
      </ul>
      <h6>TechParam:</h6>
      <hr />
      <p>SOM is calling TechParam to get provisioning information which need to be send to ADMAN and to the network provider basket.</p>
      <ul>
        <li>
          SOM is selecting the following data values to ADMAN
        <ul>
            <li>
              {"<customerFacingServicesWithinTheChannel>"} which is the list of the services and their SIK number
            </li>
            <li>
              {"<channelInfo>"} which have the value of service & technical speed together with the QOS value the line is configurated with.
            </li>
          </ul>
        </li>
        <li>SOM is selecting the following data values to network providers order / basked creation</li>
        <ul>
          <li>
            {"<openNetChannelInfo>"} which have the value of fiber linie id, speed, traffic owner and the QOS value
          </li>
        </ul>
      </ul>
      <div className="row">
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(techReply)}</pre>
        </div>
        <div className="col col-md-6">
          <pre style={{ fontSize: '0.8rem' }}>{xmlFormatter(techRequest)}</pre>
        </div>
      </div>
      <u><h5>Access Inventory Search:</h5></u>
      <img src={AI} className="img-fluid" alt="monitor" />
      <table className="table table-small table-striped table-bordered my-3">
        <thead>
          <tr>
            <th>Search attribut name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Address Id</td>
            <td>Is the specific address id</td>
          </tr>
          <tr>
            <td>Installation Id</td>
            <td>Is Nuuday Visitation internal installatiopn id and is used to be
part of the KAP ID on an order</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>Syd Energy, Nord Energy, Eniig, Thy Mors, Fibia & Fyn.
Is a drop down list to select from.</td>
          </tr>
          <tr>
            <td>Vendor Address Id</td>
            <td>Is the address id used by the network owner</td>
          </tr>
          <tr>
            <td>External Installation Id</td>
            <td>Is the installation id used by the network owner</td>
          </tr>
          <tr>
            <td>Installation Code</td>
            <td>Nuuday Visitation installation code mapping from
infrastructure and fiber status from Open Net & Fibia.</td>
          </tr>
          <tr>
            <td>Infrastructure Owner</td>
            <td>Is the infrastructure owner name used by the Open Net & Fibia</td>
          </tr>
        </tbody>
      </table>
      <p>The Access Inventory is the rows that are updated from the Open Net & Fibia integrator address file (whitelist data) from the daily updated where fiber line is created, changed or where network owner change the salable / available status from Yes to No or No to Yes.</p>
      <p>
        The preferred way is to search with the entire address and retrieve MAD ID (specific address id) by “Get Madid” which fill out or find the address id that can be used by the search button.
      </p>
      <img src={AI_RESULT} className="img-fluid" alt="monitor" />
    </div>
  )
}
